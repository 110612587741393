import i18n from 'i18next';
import moment from 'moment';
import React, { useRef } from 'react';
import { ObservationsModalImage } from './ObservationsModalImage';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { ObservationProp } from '../../../typescript/observation/observation';
import { ImageDownloader } from '../../elements/ImageDownloader';
import { AnnotationRef } from '../../elements/ImageWithAnnotation';
import { ScenarioLabel } from '../../elements/ScenarioLabel';

type Props = {
  isAnnotation: boolean;
  observation: ObservationProp;
  hideLabel: boolean;
};

export function ObservationModalDefault({
  isAnnotation,
  observation,
  hideLabel = false,
}: Props) {
  // Context
  const { getScenarioByName } = useScenarioContext();

  // Ref
  const annotationRef = useRef<AnnotationRef>(null);

  const scenario = getScenarioByName(observation.customer_scenario_label.name);

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  return (
    <>
      <ObservationsModalImage
        ref={annotationRef}
        showLabel={hideLabel}
        isAnnotation={isAnnotation || false}
        observation={observation}
      />
      <div className="d-flex justify-content-between align-items-center mt-12">
        <span className="ph-no-capture me-12">
          {moment.unix(observation.system_timestamp).format('DD MMM, HH:mm:ss')}
        </span>
        <div className="d-flex align-items-center">
          <span className="camera-name rounded-pill ph-no-capture me-12">
            {observation.camera
              ? observation.camera.name
              : i18n.t('td.camera_no_longer_exists')}
          </span>
          {!scenario ? (
            i18n.t('td.scenario_not_found')
          ) : (
            <ScenarioLabel scenario={scenario} />
          )}
        </div>

        <ImageDownloader
          imageUrl={observation?.imageUrl}
          onClick={isAnnotation ? handleExport : undefined}
        />
      </div>
    </>
  );
}
