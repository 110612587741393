import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';

interface Props {
  title: string;
  toUrl?: string;
  onClick?: () => void;
}
export function Breadcrumb({ title, toUrl, onClick }: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();

  return (
    <Button
      className="btn icon"
      variant={theme === 'dark' ? 'transparent' : ''}
      onClick={() => {
        if (toUrl) {
          navigate(toUrl);
        }
        if (onClick) {
          onClick();
        }
      }}
      style={{
        textDecoration: 'none !important',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 0,
        width: 'fit-content',
        fontWeight: 500,
      }}
    >
      <ArrowLeftIcon />
      <span className="ms-2">{title}</span>
    </Button>
  );
}
