/** @jsxImportSource @emotion/react */
import { useMutation } from '@apollo/client';
import i18n from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from './ConfirmationModal';
import { CompressObservationsTable } from './observation/CompressObservationsTable';
import {
  ObservationActionEventHandler,
  ObservationActions,
} from './observation/ObservationActions';
import { ObservationDetailsRow } from './observation/ObservationDetailsRow';
import { ObservationsModalImage } from './observation/ObservationsModalImage';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { UPDATE_OBSERVATION } from '../../graphql/mutations';
import { UPDATE_OBSERVATION_BY_ID } from '../../graphql/mutations/observation';
import { GetObservationsQueryBuilder } from '../../hooks/graphql/observations';
import { useConfirmationModal } from '../../hooks/modal';
import { useAddBookmark, useDeleteBookmark } from '../../hooks/observations';
import { spacing } from '../../scss/spacing';
import { isReportFalseFormData } from '../../typescript/components/modal';
import { ObservationProp } from '../../typescript/observation/observation';
import { BWButton } from '../elements/BWButton';
import { CustomSwitch } from '../elements/CustomSwitch';
import { ImageDownloader } from '../elements/ImageDownloader';
import { AnnotationRef } from '../elements/ImageWithAnnotation';
import { ShortCutOverlay } from '../observation-quick-view/ShortCutOverlay';

type Props = {
  observation: ObservationProp;
  buildGetObservationsQuery: GetObservationsQueryBuilder;

  hasAnnotations: boolean;
  hideLabel?: boolean;
  paginationDefaults?: {
    pageNumber?: number;
    limit?: number;
  };
  setHideLabel: (value: boolean) => void;
  onCloseModal?: () => void;
};

export function ObservationModalBody({
  buildGetObservationsQuery,
  hasAnnotations,
  observation,
  hideLabel,
  paginationDefaults,
  setHideLabel,
  onCloseModal,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // State
  const [hideDangerZone, setHideDangerZone] = useState(true);
  const [selectedObservation, setSelectedObservation] =
    useState<ObservationProp>(observation);

  useEffect(() => {
    setSelectedObservation(observation);
  }, [observation]);

  // Ref
  const annotationRef = useRef<AnnotationRef>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const [updateObservation] = useMutation(UPDATE_OBSERVATION);
  const [updateSensitiveObservation] = useMutation(UPDATE_OBSERVATION_BY_ID);
  const [addObservationBookmark] = useAddBookmark();
  const [deleteObservationBookmark] = useDeleteBookmark();

  const hasDangerZone =
    (selectedObservation.perimeter?.position.length || 0) > 0;

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  const { confirmationModal, openConfirmationModal, closeConfirmationModal } =
    useConfirmationModal();

  const openReportFalsePositiveModal = useCallback(
    (observationId: number) => {
      openConfirmationModal({
        type: 'report',
        title: i18n.t('button.report_false'),
        body: '',
        buttonType: 'primary',
        doneText: i18n.t('button.send'),
        cancelText: i18n.t('button.cancel'),
        onClose: (reason, formData) => {
          if (reason === 'confirm' && isReportFalseFormData(formData)) {
            updateObservation({
              variables: {
                id: observationId,
                data: {
                  is_false_positive: true,
                  false_positive_reason: formData.reason,
                  false_positive_comment: formData.comment,
                  date_set_false_positive: moment()
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ssZ'),
                },
              },
            });
          }

          closeConfirmationModal();
        },
      });
    },
    [openConfirmationModal, closeConfirmationModal, updateObservation],
  );

  const handleObservationUpdate: ObservationActionEventHandler = (
    observation,
    action,
    value,
  ) => {
    if (action === 'report') {
      openReportFalsePositiveModal(observation.id);
    } else if (action === 'view_details') {
      navigate(`/observation/${observation.id}`);
    } else if (action === 'bookmark') {
      const bookmarkId = observation.observation_users.find(
        ({ user_id }) => user?.id === user_id,
      )?.id;

      if (bookmarkId !== undefined) {
        deleteObservationBookmark({
          variables: {
            id: bookmarkId,
          },
        });
      } else {
        addObservationBookmark({
          variables: {
            data: {
              observation_id: observation.id,
              user_id: user?.id,
            },
          },
        });
      }
    } else {
      updateSensitiveObservation({
        variables: {
          id: observation.id,
          column: action,
          value,
        },
      });
    }
  };

  const showObservationTable = paginationDefaults !== undefined;

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (confirmationModal.show) {
        return;
      }

      if (event.key === 'd') {
        if (hasAnnotations) {
          event.preventDefault();
          setHideDangerZone(!hideDangerZone);
        }
      } else if (event.key === 'l') {
        if (hasAnnotations) {
          event.preventDefault();
          setHideLabel(!hideLabel);
        }
      } else if (event.key === 'D') {
        if (hasAnnotations) {
          event.preventDefault();
          handleExport();
        }
      } else if (event.key === 'Escape') {
        if (onCloseModal) {
          event.preventDefault();
          onCloseModal();
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [
    hideDangerZone,
    setHideLabel,
    hideLabel,
    confirmationModal.show,
    hasAnnotations,
    onCloseModal,
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: `${spacing(2)}`,
          padding: `${spacing(3)} 0`,
        }}
      >
        <BWButton
          className="button-with-loader text-nowrap"
          variant="outline"
          icon={WarningIcon}
          title={i18n.t('button.report_false')}
          onClick={() => {
            openReportFalsePositiveModal(observation.id);
          }}
        />

        <ImageDownloader
          imageUrl={observation?.imageUrl}
          onClick={hasAnnotations ? handleExport : undefined}
        />

        <ObservationActions
          observation={selectedObservation}
          hideReportFalsePositive
          onObservationAction={handleObservationUpdate}
        />
      </div>

      <Card
        className={`border border-${theme} border-radius`}
        style={{
          overflow: 'hidden',
        }}
      >
        <Card.Body
          className="p-0"
          style={{
            display: 'flex',
            // maxHeight: `min(${maxHeight}px, calc(100vh - 200px))`,
            // maxHeight: `calc(100vh - 200px)`,
            // width: 'fit-content',
            width: 'max-content',
            maxWidth: '90vw',
            maxHeight: 'calc(100vh - 188px)',
          }}
        >
          {showObservationTable && (
            <CompressObservationsTable
              buildGetObservationsQuery={buildGetObservationsQuery}
              selectedObservation={selectedObservation}
              pagination={paginationDefaults}
              onObservationSelect={(selectedObservation) => {
                setSelectedObservation(selectedObservation);
              }}
              onObservationUpdate={handleObservationUpdate}
              isConfirmationModalOpen={confirmationModal.show}
            />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            ref={imageContainerRef}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                width: 'fit-content',
                minHeight: 0,
                margin: '0 auto',
              }}
            >
              <ObservationsModalImage
                ref={annotationRef}
                showLabel={!hideLabel}
                isAnnotation={hasAnnotations}
                observation={selectedObservation}
                showDangerZone={!hideDangerZone}
              />
            </div>
            {hasAnnotations && (
              <div
                className={`border-${theme}`}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: `${spacing(4)}`,
                  padding: `${spacing(4)}  ${spacing(3)}`,
                  borderTop: '1px solid',
                }}
              >
                <CustomSwitch
                  label={`${i18n.t('modal.observation.switch.show_labels')}`}
                  checked={!hideLabel}
                  onChange={(e) => setHideLabel(!e.currentTarget.checked)}
                />
                <CustomSwitch
                  label={`${i18n.t('modal.observation.switch.danger_zone')}`}
                  checked={hasDangerZone && !hideDangerZone}
                  onChange={(e) => setHideDangerZone(!e.currentTarget.checked)}
                  disabled={!hasDangerZone}
                />
                {showObservationTable && <ShortCutOverlay />}
              </div>
            )}
            <ObservationDetailsRow observation={selectedObservation} />
          </div>
        </Card.Body>
      </Card>

      <ConfirmationModal options={confirmationModal} />
    </>
  );
}
