import axios, { AxiosError } from 'axios';
import { ZodType } from 'zod';
import { globalSiteId } from './tokens';
import { isError } from '../utils/typeUtils';

const BUDDYWISE_API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const axiosClient = axios.create({
  baseURL: BUDDYWISE_API_URL,
  headers: {
    // timeout: 10000,
    'Content-Type': 'application/json',
  },
});

export type UserToken = string | null | undefined;

export type APIResponse<TResponse = unknown, TError = Error> =
  | {
      success: true;
      status: number;
      data: TResponse;
    }
  | {
      success: false;
      status?: number;
      detail?: string;
      error: TError;
    };

export const apiPost = async <TPayload, TResponse>(
  url: string,
  token: UserToken,
  data: TPayload,
  responseSchema: ZodType,
): Promise<APIResponse<TResponse>> => {
  try {
    const response = await axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    });

    const parsedResponse = responseSchema.parse(response.data);

    return {
      success: true,
      status: response.status,
      data: parsedResponse,
    };
  } catch (error) {
    if (!isError(error)) {
      throw new Error('Unexpected error', {
        cause: error,
      });
    }

    if (error instanceof AxiosError) {
      return {
        success: false,
        status: error.response?.status,
        detail: error.response?.data.detail,
        error,
      };
    }

    return {
      success: false,
      error,
    };
  }
};
