import { useMutation, useQuery } from '@apollo/client';
import i18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urlTempImage, urlTempImageV2 } from '../apis/api-request';
import { globalSiteId } from '../apis/tokens';
import InboxIcon from '../assets/empty-comment-list.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as OfflineCamera } from '../assets/icons/offline-camera.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import { ReactComponent as UnbookmarkIcon } from '../assets/icons/start-outline.svg';
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { BWButton } from '../components/elements/BWButton';
import { CustomDropdown } from '../components/elements/CustomDropdown';
import { ImageLoader } from '../components/elements/ImageLoader';
import { ImageWithAnnotation } from '../components/elements/ImageWithAnnotation';
import { InputField } from '../components/elements/InputField';
import { Loader } from '../components/elements/Loader';
import { ScenarioLabel } from '../components/elements/ScenarioLabel';
import { ConfirmationModal } from '../components/modal/ConfirmationModal';
import { ObservationModal } from '../components/modal/ObservationModal';
import { ObservationModalDeprecated } from '../components/modal/ObservationModalDeprecated';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useScenarioContext } from '../contextapi/ScenarioProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import {
  DELETE_OBSERVATION,
  ObservationsSetInput,
  UPDATE_OBSERVATION,
} from '../graphql/mutations';
import {
  ObservationUserInsertInput,
  UPDATE_OBSERVATION_BY_ID,
} from '../graphql/mutations/observation';
import { GET_OBSERVATION } from '../graphql/queries/observation';
import { useConfirmationModal } from '../hooks/modal';
import { useAddBookmark, useDeleteBookmark } from '../hooks/observations';
import {
  isReportFalseFormData,
  ModalFormData,
  OnCloseReason,
} from '../typescript/components/modal';
import { ClientUser } from '../typescript/observation/assignee';
import {
  Comments,
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  ObservationProp,
  TimeLine,
  TitleAndValue,
} from '../typescript/observation/observation';
import { isString } from '../utils/typeUtils';

export const ObservationDetailsPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  // Context
  const { userToken, user, clientUsersList, onTokenSave, featureFlags } =
    useAuthContext();
  const { customerScenarios } = useScenarioContext();
  const { theme } = useThemeContext();

  const [observation, setObservation] = useState<ObservationProp | undefined>();
  const [isImageFound, setIsImageFound] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState(0);
  const [observationImageUrl, setObservationImageUrl] = useState<string | null>(
    null,
  );
  // const [isBookmarkId, setIsBookmarkId] = useState<number|null>(null);

  // Loaders
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [onReportLoading, setOnReportLoading] = useState(false);
  const [onDeleteLoading, setOnDeleteLoading] = useState(false);
  const [onStatusLoading, setOnStatusLoading] = useState(false);
  const [onResponderLoading, setOnResponderLoading] = useState(false);
  const [onPriorityLoading, setOnPriorityLoading] = useState(false);

  // Comment
  const [comment, setComment] = useState<string>('');

  // Modal
  const [showModal, setShowModal] = useState(false);
  const { confirmationModal, openConfirmationModal, closeConfirmationModal } =
    useConfirmationModal();

  // GraphQL
  const { loading, data } = useQuery(GET_OBSERVATION, {
    variables: {
      id: Number(slug),
    },
    fetchPolicy: 'network-only',
  });

  const [updateObservation] = useMutation(UPDATE_OBSERVATION);
  const [updateSensitiveObservation, onSensitiveObservationAction] =
    useMutation(UPDATE_OBSERVATION_BY_ID);
  const [deleteObservation, deleteActions] = useMutation(DELETE_OBSERVATION);
  const [addObservationBookmark, observationBookmark] = useAddBookmark();
  const [deleteObservationBookmark, deleteBookmark] = useDeleteBookmark();

  const isBookmarked =
    (user &&
      observation?.observation_users.some(
        ({ user_id }) => user_id === user.id,
      )) ||
    false;

  useEffect(() => {
    if (data) {
      setOnReportLoading(false);
      setOnDeleteLoading(false);
      setOnResponderLoading(false);
      setOnStatusLoading(false);
      setOnPriorityLoading(false);

      if (data.observations_by_pk) {
        const isFalsePositive: boolean =
          data.observations_by_pk.is_false_positive;
        if (!isFalsePositive) {
          if (observation?.imageUrl !== data.observations_by_pk.imageUrl) {
            setObservationImageUrl(data.observations_by_pk.imageUrl);
            setRetryCount(0);
          }
          setObservation(data.observations_by_pk);
        }
        if (isFalsePositive) {
          toast.warning(
            'This observation is reported False positive reported',
            {
              autoClose: 500,
              onClose: () => navigate(-1),
            },
          );
        }
      } else {
        toast.error(i18n.t('toast.error.observation_not_exists'), {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }

      if (deleteActions.data) {
        toast.success(i18n.t('toast.success.observation_deleted'), {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
    }
  }, [data, deleteActions.data, navigate, observation?.imageUrl]);

  useEffect(() => {
    if (onSensitiveObservationAction.data) {
      const updatedObservation =
        onSensitiveObservationAction.data.observation_update_sensitive_column;
      setObservation(updatedObservation[0].observation[0]);
      setComment('');
    }
  }, [onSensitiveObservationAction.data]);

  const getImageUrlV1 = async (): Promise<string | null> => {
    if (!userToken || !observation) {
      return null;
    }

    const response = await urlTempImage(userToken, {
      observation_id: observation.id,
    });

    if (response?.status === 401) {
      onTokenSave('');
    }

    return response.data.message;
  };

  const getImageUrlV2 = async (): Promise<string | null> => {
    if (!userToken || !observation) {
      return null;
    }

    const response = await urlTempImageV2(userToken, {
      customer_id: user?.customer.id,
      site_id: Number(globalSiteId),
      request_id: observation.requestId,
      version: 'original',
    });

    if (response?.status === 401) {
      onTokenSave('');
    }

    if (response.data.detail === 'Image not found') {
      return null;
    }

    return response.data.signed_url;
  };

  const updateImageUrl = async () => {
    setIsImageLoading(true);
    const showThumbnail = featureFlags.image?.show_thumbnail;
    if (userToken && observation && retryCount < 2) {
      try {
        const imageUrlV2 = showThumbnail ? await getImageUrlV2() : null;
        const imageUrlV1 = imageUrlV2 ? null : await getImageUrlV1();

        const imageUrl = imageUrlV2 || imageUrlV1;
        if (isString(imageUrl)) {
          setObservationImageUrl(imageUrl);
          setIsImageFound(true);
        } else {
          setIsImageFound(false);
        }
      } catch (fetchError) {
        console.error('Error fetching new image URL:', fetchError);

        setIsImageFound(false);
      } finally {
        setIsImageLoading(false);
        setRetryCount((prevCount) => prevCount + 1); // Increment retry count
      }
    } else {
      setIsImageLoading(false);
      setIsImageFound(true);
    }
  };

  function onBookmark(value: ObservationUserInsertInput) {
    if (isBookmarked) {
      deleteObservationBookmark({
        variables: {
          id: observation?.observation_users[0].id,
        },
      });
    } else {
      addObservationBookmark({
        variables: {
          data: value,
        },
      });
    }
  }

  function onSensitiveObservation(column: string, value: string) {
    updateSensitiveObservation({
      variables: {
        id: [Number(slug)],
        column,
        value,
      },
    });
  }

  function onUpdateObservation(value: ObservationsSetInput) {
    updateObservation({
      variables: {
        id: Number(slug),
        data: value,
      },
    });
  }

  function onDeleteObservation() {
    deleteObservation({
      variables: {
        id: Number(slug),
      },
    });
  }

  const handleModalClose = (
    action: 'report' | 'delete',
    reason: OnCloseReason,
    formData?: ModalFormData,
  ) => {
    if (reason === 'confirm' && observation) {
      if (action === 'delete') {
        onDeleteObservation();
        setOnDeleteLoading(true);
      } else if (action === 'report') {
        if (isReportFalseFormData(formData)) {
          onUpdateObservation({
            is_false_positive: true,
            false_positive_reason: formData.reason,
            false_positive_comment: formData.comment,
            date_set_false_positive: moment()
              .utc()
              .format('YYYY-MM-DDTHH:mm:ssZ'),
          });
        }
        setOnReportLoading(true);
      }
    }

    closeConfirmationModal();
  };

  const filteredScenario = customerScenarios.filter(
    (element) => element.name === observation?.customer_scenario_label.name,
  );
  const filteredPriority = OBSERVATION_PRIORITIES.filter(
    (priority) => priority.value === observation?.priority,
  );
  const filteredStatus = OBSERVATION_STATUSES.filter(
    (status) => status.value === observation?.status,
  );
  const filteredAssignee = clientUsersList.filter(
    (item) => item.email === observation?.responder,
  )[0];

  const showDelete = featureFlags.observations.show_delete;
  const showAnnotation = featureFlags.observations.show_annotation;
  const isAnnotation =
    observation && showAnnotation && observation.id >= Number(showAnnotation);

  return (
    <div className="observation">
      <Breadcrumb
        title={`${i18n.t('landing.title')}`}
        onClick={() => navigate(-1)}
      />
      <Row>
        <Col md={12} className="mb-32">
          <h5 className="m-0">{i18n.t('observation.title')}</h5>
        </Col>
        {loading && (
          <Col
            md={12}
            className="vh-100 d-flex justify-content-center align-items-center"
          >
            <Loader main />
          </Col>
        )}
        {observation && [
          <Col md={12} className="mb-24" key="buttons">
            <div className="d-flex">
              <BWButton
                className="button-with-loader me-2"
                variant="outline"
                icon={WarningIcon}
                title={i18n.t('button.report_false')}
                onClick={() => {
                  openConfirmationModal({
                    type: 'report',
                    title: i18n.t('button.report_false'),
                    body: '',
                    buttonType: 'primary',
                    doneText: i18n.t('button.send'),
                    cancelText: i18n.t('button.cancel'),
                    onClose: (reason, formData) =>
                      handleModalClose('report', reason, formData),
                  });
                }}
                loading={onReportLoading}
                trackingContext={{
                  action: 'open_report_false_positive_modal',
                }}
              />

              <CustomDropdown
                title={i18n.t('button.change_assignee')}
                hideContextData
                className="me-2"
                dropdownData={clientUsersList.map((item: ClientUser) => ({
                  title: item.username ?? item.email,
                  value: item.email,
                }))}
                disabled={onResponderLoading}
                loading={onResponderLoading}
                onClick={(item) => {
                  if (observation.responder !== item.value) {
                    setOnResponderLoading(true);
                    onSensitiveObservation('responder', `${item.value}`);
                  }
                  return true;
                }}
                trackingContext={{
                  action: 'change_assignee',
                }}
              />

              <BWButton
                title={
                  isBookmarked
                    ? i18n.t('button.remove_bookmark')
                    : i18n.t('button.bookmark')
                }
                className="button-with-loader me-2"
                variant="outline"
                disabled={observationBookmark.loading || deleteBookmark.loading}
                loading={observationBookmark.loading || deleteBookmark.loading}
                icon={UnbookmarkIcon}
                onClick={() => {
                  if (slug && user) {
                    onBookmark({ observation_id: slug, user_id: user.id });
                  }
                }}
                trackingContext={{
                  action: isBookmarked ? 'remove_bookmark' : 'bookmark',
                }}
              />

              <BWButton
                title={i18n.t('button.share')}
                className="button-with-loader me-2"
                variant="outline"
                icon={ShareIcon}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  toast.success(i18n.t('toast.success.share_observation'));
                }}
                trackingContext={{
                  action: 'share_observation',
                }}
              />

              {showDelete && (
                <BWButton
                  title={i18n.t('button.delete')}
                  className="button-with-loader"
                  variant="outline"
                  icon={DeleteIcon}
                  loading={onDeleteLoading}
                  onClick={() => {
                    openConfirmationModal({
                      type: 'default',
                      title: i18n.t('modal.delete_observation.title'),
                      body: i18n.t('modal.delete_observation.body'),
                      buttonType: 'danger',
                      doneText: i18n.t('modal.delete_observation.yes'),
                      cancelText: i18n.t('modal.delete_observation.no'),
                      onClose: (reason) => handleModalClose('delete', reason),
                    });
                  }}
                  trackingContext={{
                    action: 'delete_observation',
                    context: { page: 'observation_details' },
                  }}
                />
              )}
            </div>
          </Col>,

          <Col md={8} key="body">
            <div className={`border border-${theme} border-radius p-4`}>
              <Row>
                <Col md={12} lg={6} className="danger-zone-modal">
                  {isImageFound ? (
                    <>
                      <ImageLoader
                        className={`${!isImageLoading && 'd-none'}`}
                      />
                      {isAnnotation ? (
                        <ImageWithAnnotation
                          className={`ph-no-capture add-cursor ${isImageLoading && 'd-none'}`}
                          containerWidth="100%"
                          containerHeight="100%"
                          isImageLoading={isImageLoading}
                          onLoad={() => setIsImageLoading(false)}
                          onError={() => updateImageUrl()}
                          onClick={() => setShowModal(true)}
                          imageUrl={observationImageUrl || ''}
                          detections={observation.detections}
                        />
                      ) : (
                        <Image
                          className={`ph-no-capture add-cursor ${isImageLoading && 'd-none'}`}
                          width="100%"
                          height="100%"
                          onLoad={() => setIsImageLoading(false)}
                          onError={() => updateImageUrl()}
                          onClick={() => setShowModal(true)}
                          src={observationImageUrl || ''}
                        />
                      )}
                    </>
                  ) : (
                    <div
                      className={`observation-image-container-offline ${theme === 'light' ? 'dark' : 'light'}`}
                    >
                      <OfflineCamera />
                      <p>Camera no longer exists.</p>
                    </div>
                  )}
                </Col>
                <Col md={12} lg={6}>
                  <h6 className="mb-3 ph-no-capture">
                    {observation.camera
                      ? observation.camera.name
                      : 'Camera no longer exists'}
                  </h6>
                  <div className="capitalize-letter d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.status')}:
                    </p>{' '}
                    {/* remove filter form statusList later when the resolve and resolved typeo is fixed */}
                    <CustomDropdown
                      title={
                        filteredStatus.length !== 0
                          ? i18n.t(filteredStatus[0].title)
                          : i18n.t('button.select')
                      }
                      className="me-2"
                      dropdownData={OBSERVATION_STATUSES.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex((t) => t.title === value.title),
                      ).map((item: TitleAndValue) => ({
                        title: `${i18n.t(item.title)}`,
                        value: item.value,
                      }))}
                      disabled={onStatusLoading}
                      loading={onStatusLoading}
                      onClick={(item) => {
                        if (filteredStatus[0] === undefined) {
                          setOnStatusLoading(true);
                          onSensitiveObservation('status', `${item.value}`);
                        } else if (filteredStatus[0].value !== item.value) {
                          setOnStatusLoading(true);
                          onSensitiveObservation('status', `${item.value}`);
                        }
                      }}
                      trackingContext={{
                        action: 'change_status',
                        context: {
                          page: 'observation_details',
                        },
                      }}
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.priority')}:
                    </p>
                    <CustomDropdown
                      title={
                        observation.priority
                          ? i18n.t(filteredPriority[0].title)
                          : i18n.t('button.select')
                      }
                      className="me-2"
                      dropdownData={OBSERVATION_PRIORITIES.map(
                        (item: TitleAndValue) => ({
                          title: `${i18n.t(item.title)}`,
                          value: item.value,
                        }),
                      )}
                      disabled={onPriorityLoading}
                      loading={onPriorityLoading}
                      onClick={(item) => {
                        if (filteredPriority[0].value !== item.value) {
                          setOnPriorityLoading(true);
                          onSensitiveObservation('priority', `${item.value}`);
                        }
                      }}
                      trackingContext={{
                        action: 'change_priority',
                        context: {
                          page: 'observation_details',
                        },
                      }}
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.created')}:
                    </p>
                    <p className="m-0 ph-no-capture">
                      {moment
                        .unix(observation?.system_timestamp)
                        .format('MMM DD, HH:mm:ss')}
                    </p>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.scenario')}:
                    </p>
                    <p className="m-0">
                      {!filteredScenario[0] ? (
                        i18n.t('td.scenario_not_found')
                      ) : (
                        <ScenarioLabel scenario={filteredScenario[0]} />
                      )}
                    </p>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.assignee')}:
                    </p>
                    {observation.responder && filteredAssignee ? (
                      <Link
                        className="ph-no-capture"
                        style={{ textDecoration: 'none' }}
                        to={`/profile/${filteredAssignee.id}`}
                      >
                        <BWButton
                          variant="link"
                          title={
                            filteredAssignee.username || observation.responder
                          }
                        />
                      </Link>
                    ) : (
                      i18n.t('td.not_assigned')
                    )}
                  </div>

                  {/* <p className="m-0">
                    <span>
                      {i18n.t('td.duration')}
                      :
                    </span>
                    {' '}
                    {moment.unix(observation!.inferenceTime).format('HH:mm:ss')}
                  </p> */}
                </Col>
              </Row>
            </div>

            <div
              className={`border border-${theme} border-radius p-4 mt-4 hidden-scroll-y`}
              style={{ height: 350 }}
            >
              <h6>{i18n.t('timeline.title')}</h6>

              {observation.timeline && (
                <Table
                  responsive="sm alerts-table overflow-hidden"
                  variant={theme}
                >
                  <tbody>
                    {[...observation.timeline] // Create a shallow copy of observation.timeline
                      .sort(
                        (a: TimeLine, b: TimeLine) => b.timestamp - a.timestamp,
                      )
                      .map((item: TimeLine) => (
                        <tr
                          style={{ height: 48 }}
                          key={item.timestamp}
                          className="ph-no-capture"
                        >
                          <td>
                            {moment
                              .unix(item.timestamp)
                              .format('YYYY-MM-DD, HH:mm:ss')}
                          </td>
                          <td>{item.message}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>,

          <Col md={4} key="comment">
            <div className={`border border-${theme} border-radius h-100 p-4`}>
              <Row className="h-100">
                <Col
                  md="12"
                  className="d-flex flex-column justify-content-between hidden-scroll-y"
                  style={{ height: 638 }}
                >
                  <h6>{i18n.t('comments.title')}</h6>
                  {(observation.comments || []).length !== 0 ? (
                    <div className="observation-comments">
                      {(observation.comments || []).map((item: Comments) =>
                        item.sender === user?.email ? (
                          <div className="comment-sender" key={item.timestamp}>
                            <p className="name text-end">
                              {i18n.t('text.you')}
                            </p>
                            <p className="message ph-no-capture">{`${item.comment} : ${moment.unix(item.timestamp).format('h:mma')}`}</p>
                          </div>
                        ) : (
                          <div
                            className="comment-receiver"
                            key={item.timestamp}
                          >
                            <p className="name ph-no-capture">{item.sender}</p>
                            <p className="message ph-no-capture">{`${item.comment}  ${moment.unix(item.timestamp).format('h:mma')}`}</p>
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <div className="observation-comments justify-content-center align-items-center text-center">
                      <Image className="mb-3" src={InboxIcon} />

                      <h6>{i18n.t('observation.comments.title')}</h6>
                      <p>{i18n.t('observation.comments.body')}</p>
                    </div>
                  )}

                  <div
                    className={`observation-input observation-input-${theme} border border-${theme} border-radius`}
                  >
                    <InputField
                      type="text"
                      value={comment}
                      className={`mb-12 input-${theme}`}
                      controlId="comment"
                      placeholder={`${i18n.t('input.comment.placeholder')}`}
                      onChange={(e) => setComment(e.target.value)}
                      disabled={onSensitiveObservationAction.loading}
                    />
                    <div className="d-flex justify-content-end">
                      <BWButton
                        className="button-with-loader"
                        variant={theme}
                        title={i18n.t('button.send')}
                        loading={onSensitiveObservationAction.loading}
                        disabled={
                          comment.length === 0 ||
                          onSensitiveObservationAction.loading
                        }
                        onClick={() => {
                          onSensitiveObservation('comments', comment);
                        }}
                        trackingContext={{
                          action: 'add_comment',
                          context: { page: 'observation_details' },
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>,
        ]}
      </Row>

      <ConfirmationModal options={confirmationModal} />

      {featureFlags.observations.show_extended_quick_view ? (
        <ObservationModal
          showModal={showModal}
          observation={observation}
          onCloseClick={() => setShowModal(false)}
        />
      ) : (
        <ObservationModalDeprecated
          observation={observation}
          showModal={showModal}
          onCloseClick={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
