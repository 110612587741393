/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { ScenarioLabel } from '../elements/ScenarioLabel';

interface Props {
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
}
export function CustomLegend({ payload }: Props) {
  const { customerScenarios } = useScenarioContext();

  const scenarioList = payload && payload.map((item) => item.dataKey);

  const filteredScenario =
    scenarioList &&
    customerScenarios.filter((element) => scenarioList.includes(element.name));

  if (payload) {
    return (
      <div className="d-flex justify-content-center mt-12 gap-3">
        {filteredScenario?.map((item) => (
          <ScenarioLabel key={item.name} scenario={item} />
        ))}
      </div>
    );
  }
  return null;
}
