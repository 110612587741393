/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import VisibilityObserver from 'react-visibility-observer';
import {
  ObservationActionEventHandler,
  ObservationActions,
} from './ObservationActions';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  GetObservationsQueryBuilder,
  isGetAlertObservationsQueryResponse,
  isGetBookmarkedObservationsQueryResponse,
  isGetGroupObservationQueryResponse,
  useGetObservationsQuery,
} from '../../../hooks/graphql/observations';
import { GRAY_SUBTLE } from '../../../scss/colors';
import { spacing } from '../../../scss/spacing';
import { ObservationProp } from '../../../typescript/observation/observation';
import { CustomPagination } from '../../elements/CustomPagination';
import { LazyLoadedImage } from '../../elements/LazyLoadedImage';

type Props = {
  buildGetObservationsQuery: GetObservationsQueryBuilder;
  pagination: {
    limit?: number;
    pageNumber?: number;
    totalPages?: number;
    isOrder?: boolean;
  };
  selectedObservation: ObservationProp;
  isConfirmationModalOpen: boolean;
  onObservationSelect: (observation: ObservationProp) => void;
  onObservationUpdate: ObservationActionEventHandler;
  onObservationLoading?: (loading: boolean) => void;
};

export function CompressObservationsTable({
  buildGetObservationsQuery,
  pagination,
  selectedObservation,
  isConfirmationModalOpen,
  onObservationLoading,
  onObservationSelect,
  onObservationUpdate,
}: Props) {
  const { theme } = useThemeContext();
  const { user } = useAuthContext();

  const [totalPages, setTotalPages] = useState(pagination.totalPages || 1);
  const [pageNumber, setPageNumber] = useState(pagination.pageNumber || 0);
  const { limit = 10, isOrder = true } = pagination;
  const offset = (pageNumber - 1) * limit;

  const [globalIndex, setGlobalIndex] = useState(offset);

  const [variant, queryVariables] = buildGetObservationsQuery({
    offset,
    limit,
    isOrder,
  });
  const { loading, data } = useGetObservationsQuery(variant, queryVariables);

  useEffect(() => {
    if (data) {
      if (isGetAlertObservationsQueryResponse(data)) {
        setTotalPages(
          Math.ceil(
            data.alert.alert_incident_observations_aggregate.aggregate.count /
              limit,
          ),
        );
      } else if (isGetBookmarkedObservationsQueryResponse(data)) {
        setTotalPages(
          Math.ceil(data.observation_user_aggregate.aggregate.count / limit),
        );
      } else if (isGetGroupObservationQueryResponse(data)) {
        setTotalPages(
          Math.ceil(
            data.fetch_observations_by_group_id_aggregate.aggregate.count /
              limit,
          ),
        );
      } else {
        setTotalPages(
          Math.ceil(data.observations_aggregate.aggregate.count / limit),
        );
      }
    }
  }, [data, limit]);

  useEffect(() => {
    onObservationLoading?.(loading);
  }, [loading, onObservationLoading]);

  const observations = useMemo(() => {
    if (data) {
      if (isGetAlertObservationsQueryResponse(data)) {
        return data.alert.alert_incident_observations.map(
          (item) => item.observation,
        );
      }

      if (isGetBookmarkedObservationsQueryResponse(data)) {
        return data.observation_user.map((item) => item.observation);
      }

      if (isGetGroupObservationQueryResponse(data)) {
        return data.fetch_observations_by_group_id;
      }

      return data.observations;
    }

    return [];
  }, [data]);

  useEffect(() => {
    const updatedObservation = observations.find(
      (observation) => observation.id === selectedObservation.id,
    );
    if (updatedObservation) {
      onObservationSelect(updatedObservation);
    }
  }, [observations, selectedObservation, onObservationSelect]);

  useEffect(() => {
    if (observations.length > 0) {
      const selectedIndex = observations.findIndex(
        (observation: ObservationProp) =>
          observation.id === selectedObservation.id,
      );
      const localIndex = globalIndex - offset;
      if (selectedIndex === -1) {
        onObservationSelect(observations[localIndex]);
      } else if (selectedIndex !== localIndex) {
        setGlobalIndex(selectedIndex + offset);
      }
    }
  }, [
    observations,
    selectedObservation,
    globalIndex,
    offset,
    onObservationSelect,
  ]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (isConfirmationModalOpen) {
        return;
      }

      const currentIndex = observations.findIndex(
        (observation: ObservationProp) =>
          observation.id === selectedObservation.id,
      );
      if (currentIndex === -1) {
        return;
      }

      const isFirstObservation = currentIndex === 0;
      const isLastObservation = currentIndex === observations.length - 1;

      switch (event.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          event.preventDefault();
          if (isLastObservation) {
            if (pageNumber < totalPages) {
              setPageNumber(pageNumber + 1);
              setGlobalIndex((prev) => prev + 1);
            }
          } else {
            onObservationSelect(observations[currentIndex + 1]);
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          event.preventDefault();
          if (isFirstObservation) {
            if (pageNumber > 1) {
              setPageNumber(pageNumber - 1);
              setGlobalIndex((prev) => prev - 1);
            }
          } else {
            onObservationSelect(observations[currentIndex - 1]);
          }
          break;
        case 'F':
          event.preventDefault();
          onObservationUpdate(selectedObservation, 'report');
          break;
        case 'A':
          event.preventDefault();
          onObservationUpdate(selectedObservation, 'status', 'acknowledged');
          break;
        case 'R':
          event.preventDefault();
          onObservationUpdate(selectedObservation, 'status', 'resolved');
          break;
        case 'B':
          event.preventDefault();
          onObservationUpdate(selectedObservation, 'bookmark');
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [
    isConfirmationModalOpen,
    observations,
    selectedObservation,
    onObservationSelect,
    onObservationUpdate,
    pageNumber,
    totalPages,
    limit,
    user,
  ]);

  const selectedItemRef = useRef<HTMLTableRowElement>(null);

  const scrollToElement = () => {
    const { current } = selectedItemRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    scrollToElement();
  }, [selectedObservation]);

  return (
    <div
      className={`border-${theme}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRight: '1px solid',
      }}
    >
      <div
        className={`sm alerts-table border-${theme}`}
        style={{
          overflowY: 'scroll',
          flex: 1,
          contain: 'size',
          minWidth: 280,
        }}
      >
        <Table
          style={{
            borderBottom: '1px solid',
          }}
          hover
          variant={theme}
        >
          <thead
            className="weight-500"
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <tr>
              <td>{i18n.t('td.snapshot')}</td>
              <td>{i18n.t('td.date')}</td>
              <td>{i18n.t('td.action')}</td>
            </tr>
          </thead>
          <tbody>
            {observations.map((observation: ObservationProp) => {
              const isSelected = observation.id === selectedObservation.id;

              return (
                <tr
                  key={observation.id}
                  style={{
                    backgroundColor: isSelected ? GRAY_SUBTLE : 'white',
                    cursor: 'pointer',
                  }}
                  onClick={() => onObservationSelect(observation)}
                  css={css`
                    td {
                      background-color: transparent;
                    }
                  `}
                  ref={isSelected ? selectedItemRef : null}
                >
                  <td>
                    <VisibilityObserver triggerOnce threshold={0.2}>
                      <LazyLoadedImage observation={observation} />
                    </VisibilityObserver>
                  </td>
                  <td
                    style={{
                      maxWidth: '40px',
                    }}
                    className="p-0 ph-no-capture"
                  >
                    {moment
                      .unix(observation.system_timestamp)
                      .format('DD MMM, HH:mm:ss')}
                  </td>
                  <td>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      aria-hidden
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ObservationActions
                        observation={observation}
                        onObservationAction={onObservationUpdate}
                        isThreeDot
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          borderTop: '1px solid',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: spacing(3),
          padding: `${spacing(2)}  ${spacing(3)}`,
          minHeight: 60,
        }}
        className={`border-${theme}`}
        css={css`
          flex-wrap: wrap;
          justify-content: space-between;

          @media (max-width: 768px) {
            justify-content: flex-start;
          }
        `}
      >
        <CustomPagination
          currentPage={pageNumber}
          totalPages={totalPages}
          range={1}
          hideText
          onPageChange={(pageNumber) => {
            setPageNumber(pageNumber);
            setGlobalIndex((pageNumber - 1) * limit);
          }}
        />
      </div>
    </div>
  );
}
