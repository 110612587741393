import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useAuthContext } from './AuthProvider';
import { useThemeContext } from './ThemeProvider';
import { Camera } from '../hooks/graphql/camera';
import { ObservationSet } from '../hooks/graphql/chart';
import {
  filterScenariosByApiName,
  filterScenariosByCamera,
  filterScenariosByName,
  findScenarioByApiName,
  findScenarioById,
  findScenarioByName,
  mapScenarioLabelToScenario,
  mapToScenarioName,
  Scenario,
  ScenarioName,
  sortByDefinedOrder,
} from '../lib/features/scenario';
import { isDefined, isNotNull } from '../utils/typeUtils';

type ScenarioContextType = {
  customerScenarios: Scenario[];

  getScenarioById: (scenarioId?: number) => Scenario | undefined;
  getScenarioByName: (scenarioName?: ScenarioName) => Scenario | undefined;
  getScenarioByApiName: (scenarioApiName?: string) => Scenario | undefined;

  getScenariosByName: (scenarioNames: ScenarioName[]) => Scenario[];
  getScenariosByApiName: (scenarioApiNames: string[]) => Scenario[];
  getScenariosByCamera: (camera?: Camera) => Scenario[];

  getScenariosByObservationSets: (
    observationGroups: ObservationSet[],
  ) => Scenario[];
};

const ScenarioContext = createContext<ScenarioContextType | undefined>(
  undefined,
);

export const useScenarioContext = () => {
  const context = useContext(ScenarioContext);
  if (!context) {
    throw new Error(
      'useScenarioContext must be used within a ScenarioProvider',
    );
  }

  return context;
};

export function ScenarioProvider({ children }: PropsWithChildren) {
  const { theme } = useThemeContext();
  const { user } = useAuthContext();

  const scenarioLabels = user?.customer.customer_scenario_labels;
  const customerScenarios = useMemo(() => {
    if (!scenarioLabels) return [];

    return scenarioLabels
      .map(mapScenarioLabelToScenario(theme))
      .filter(isNotNull)
      .sort(sortByDefinedOrder);
  }, [scenarioLabels, theme]);

  const getScenarioById = useCallback(
    (scenarioId?: number) => findScenarioById(customerScenarios, scenarioId),
    [customerScenarios],
  );

  const getScenarioByName = useCallback(
    (scenarioName?: ScenarioName) =>
      findScenarioByName(customerScenarios, scenarioName),
    [customerScenarios],
  );

  const getScenarioByApiName = useCallback(
    (scenarioApiName?: string) =>
      findScenarioByApiName(customerScenarios, scenarioApiName),
    [customerScenarios],
  );

  const getScenariosByName = useCallback(
    (scenarioNames: ScenarioName[]) =>
      filterScenariosByName(customerScenarios, scenarioNames),
    [customerScenarios],
  );

  const getScenariosByApiName = useCallback(
    (scenarioApiNames: string[]) =>
      filterScenariosByApiName(customerScenarios, scenarioApiNames),
    [customerScenarios],
  );

  const getScenariosByCamera = useCallback(
    (camera?: Camera) => filterScenariosByCamera(customerScenarios, camera),
    [customerScenarios],
  );

  const getScenariosByObservationSets = useCallback(
    (observationGroups: ObservationSet[]) => {
      const scenarioNames = Array.from(
        new Set(
          observationGroups.flatMap(
            (observation) => observation.scenario_names,
          ),
        ),
      )
        .map(mapToScenarioName)
        .filter(isDefined);

      return getScenariosByName(scenarioNames);
    },
    [getScenariosByName],
  );

  const context = useMemo(
    () => ({
      customerScenarios,
      getScenarioById,
      getScenarioByName,
      getScenarioByApiName,
      getScenariosByName,
      getScenariosByApiName,
      getScenariosByCamera,
      getScenariosByObservationSets,
    }),
    [
      customerScenarios,
      getScenarioById,
      getScenarioByName,
      getScenarioByApiName,
      getScenariosByName,
      getScenariosByApiName,
      getScenariosByCamera,
      getScenariosByObservationSets,
    ],
  );

  return (
    <ScenarioContext.Provider value={context}>
      {children}
    </ScenarioContext.Provider>
  );
}
