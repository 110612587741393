import { gql } from '@apollo/client';
import { GROUP_FRAGMENT } from './groups';
import { OBSERVATION_FRAGMENT } from './observation';

export const GET_SCENARIOS = gql`
  query GET_SCENARIOS {
    scenarios {
      id
      name
    }
  }
`;
export const GET_SCENARIO_DATA = gql`
  ${GROUP_FRAGMENT}
  ${OBSERVATION_FRAGMENT}

  query GET_SCENARIO_DATA_BY_ID(
    $limit: Int
    $from: Int!
    $until: Int!
    $group_id: Int!
    $camera_ids_string: _int4
    $camera_ids: [Int]
    $offset: Int
    $order_by: [observations_order_by!]
    $where: observations_bool_exp
  ) {
    fetch_observations_by_group_id(
      args: {
        from: $from
        until: $until
        group_id: $group_id
        camera_ids: $camera_ids_string
      }
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      ...ObservationFragment
    }

    observation_group_statistics(
      group_id: $group_id
      from: $from
      until: $until
      camera_ids: $camera_ids
    ) {
      ...GroupFragment
    }

    fetch_observations_by_group_id_aggregate(
      args: {
        group_id: $group_id
        from: $from
        until: $until
        camera_ids: $camera_ids_string
      }
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;
