import React from 'react';
import { TrackingProvider } from '../contextapi/TrackingProvider';

export const withPageContext = (children: JSX.Element, pageName: string) => (
  <TrackingProvider page={pageName}>{children}</TrackingProvider>
);

export const withElementContext = (children: JSX.Element, element: string) => (
  <TrackingProvider element={element}>{children}</TrackingProvider>
);
