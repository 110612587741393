import React, { useCallback, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useVisibilityObserver } from 'react-visibility-observer';
import { ImageLoader } from './ImageLoader';
import { ImageWithAnnotation } from './ImageWithAnnotation';
import { ReactComponent as OfflineCamera } from '../../assets/icons/offline-camera.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { useObservationImage } from '../../hooks/image';
import { ObservationProp } from '../../typescript/observation/observation';
import { isNull, isUndefined } from '../../utils/typeUtils';

type Props = {
  observation: ObservationProp;
  onImageClick?: (observation: ObservationProp) => void;
};

export function LazyLoadedImage({ observation, onImageClick }: Props) {
  const { featureFlags } = useAuthContext();
  const { theme } = useThemeContext();
  const { isVisible } = useVisibilityObserver();
  const { imageUrl, fetchImageUrl } = useObservationImage(
    observation,
    'thumbnail',
  );

  const isImageLoading = isUndefined(imageUrl);
  const isImageNotFound = isNull(imageUrl);

  useEffect(() => {
    if (isVisible && !imageUrl) {
      fetchImageUrl();
    }
  }, [isVisible, imageUrl, fetchImageUrl]);

  const handleImageClick = useCallback(
    (e: React.MouseEvent) => {
      if (onImageClick) {
        e.stopPropagation();
        onImageClick({
          ...observation,
          imageUrl: imageUrl || observation.imageUrl,
        });
      }
    },
    [onImageClick, observation, imageUrl],
  );

  if (!isVisible || isImageLoading) {
    return (
      <ImageLoader
        className={`loading-image loading-image-${theme} ms-2 ph-no-capture`}
      />
    );
  }

  if (isImageNotFound) {
    return (
      <div
        className={`observation-image-container-offline ${theme === 'light' ? 'dark' : 'light'}`}
        style={{ width: 90, height: 50 }}
      >
        <OfflineCamera width="50%" height="50%" />
      </div>
    );
  }

  const showAnnotation = featureFlags.observations.show_annotation;
  const hasAnnotations =
    showAnnotation && observation.id >= Number(showAnnotation);

  if (hasAnnotations) {
    return (
      <ImageWithAnnotation
        className="add-cursor ph-no-capture"
        containerWidth="90px"
        containerHeight="50px"
        imageUrl={imageUrl}
        hideLabel
        detections={observation.detections}
        onClick={handleImageClick}
      />
    );
  }

  return (
    <Image
      className="add-cursor ms-2 ph-no-capture"
      width="90"
      height="50"
      src={imageUrl}
      onClick={handleImageClick}
    />
  );
}
