import { useLazyQuery } from '@apollo/client';
import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { getCameraAnalytics } from '../apis/api-request';
import { ObservationItem } from '../components/camera-analytics/ObservationItem';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import {
  CustomDropdown,
  mapScenarioToDropdownProps,
} from '../components/elements/CustomDropdown';
import { Loader } from '../components/elements/Loader';
import { ObservationModal } from '../components/modal/ObservationModal';
import { ObservationModalDeprecated } from '../components/modal/ObservationModalDeprecated';
import { ShowImageModal } from '../components/modal/ShowImageModal';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useScenarioContext } from '../contextapi/ScenarioProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { GET_OBSERVATIONS } from '../graphql/queries/observation';
import { getScenarioTitle, Scenario } from '../lib/features/scenario';
import {
  AnalyticsProps,
  ObservationProp,
} from '../typescript/observation/observation';
import { isDefined } from '../utils/typeUtils';

export const CameraAnalyticsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { userToken, onTokenSave, featureFlags } = useAuthContext();
  const { customerScenarios, getScenarioById } = useScenarioContext();
  const { theme } = useThemeContext();

  const [selectedScenario, setSelectedScenario] = useState<Scenario>();
  const [analytics, setAnalytics] = useState<AnalyticsProps>();
  const [heatMapImage, setHeatMapImage] = useState<string>('');
  const [observations, setObservations] = useState<Array<ObservationProp>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedObservation, setSelectedObservation] =
    useState<ObservationProp>();

  const { timeRange } = useTimeRangeContext();

  // Search queries
  const scenarioId = searchParams.get('scenarioId')
    ? decodeURI(String(searchParams.get('scenarioId')))
    : customerScenarios[0].id;

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const { slug } = useParams<{ slug: string }>();
  const cameraId = Number(slug);
  const [observationIds, setObservationIds] = useState<Array<number>>([]);
  const [fetchObservations, lastObservations] = useLazyQuery(GET_OBSERVATIONS, {
    variables: {
      limit: 5,
      offset: 0,
      where: { id: { _in: observationIds } },
      orderBy: 'desc',
    },
    fetchPolicy: 'network-only',
  });

  const subscribe = useCallback(
    async (scenario: Scenario) => {
      if (userToken) {
        const param = {
          camera_id: cameraId,
          scenario: scenario.name,
          timestamp: {
            from: timeRange.value,
            until: timeRange.end,
          },
        };
        setLoading(true);
        setObservations([]);
        const analyticsRes = await getCameraAnalytics(userToken, param);
        if (analyticsRes?.status === 401) {
          onTokenSave('');
        }
        if (analyticsRes?.data.last_five_observations.length === 0) {
          setLoading(false);
        }
        setObservationIds(analyticsRes?.data.last_five_observations || []);
        setAnalytics(analyticsRes?.data);
        setHeatMapImage(analyticsRes?.data.heatmap_image_url);
      }
    },
    [onTokenSave, cameraId, userToken, timeRange],
  );

  useEffect(() => {
    const scenario = getScenarioById(Number(scenarioId));
    if (scenario) {
      subscribe(scenario);
      setSelectedScenario(scenario);
    }
  }, [timeRange, selectedScenario, scenarioId, subscribe, getScenarioById]);

  useEffect(() => {
    if (observationIds.length !== 0) {
      fetchObservations();
    }
  }, [fetchObservations, observationIds]);

  useEffect(() => {
    if (lastObservations.data) {
      setLoading(false);
      setObservations(lastObservations.data.observations);
    }
  }, [lastObservations]);

  if (loading) {
    return (
      <Row className="camera-analytics">
        <Col className="empty-list" md={12}>
          <Loader main />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row className="camera-analytics">
        {selectedScenario && (
          <>
            <Col lg={12}>
              <Breadcrumb
                title={`${i18n.t('cameras.title')}`}
                toUrl="/cameras"
              />
            </Col>
            <Col lg={12} className="mb-32 d-flex justify-content-between">
              <h5 className="">{i18n.t('camera_analytics.title')}</h5>
              <div className="d-flex">
                <TimeRangeDropdown />
                <CustomDropdown
                  variant="scenario"
                  className="rounded-pill ms-12"
                  title={getScenarioTitle(selectedScenario)}
                  selectAllTitle={`${i18n.t('button.select_all')}`}
                  dropdownData={customerScenarios.map(
                    mapScenarioToDropdownProps,
                  )}
                  onClick={({ scenario }) => {
                    if (!scenario) {
                      return;
                    }

                    setSelectedScenario(scenario);
                    setSearchParams((searchParams) => {
                      if (isDefined(scenario.id)) {
                        searchParams.set('scenarioId', String(scenario.id));
                      } else {
                        searchParams.delete('scenarioId');
                      }
                      return searchParams;
                    });
                  }}
                />
              </div>
            </Col>
          </>
        )}
        {analytics?.message ? (
          <p>{i18n.t('analytics.no_data_found')}</p>
        ) : (
          <>
            <Col md={7}>
              <div key="1" className="incidents-count mb-24">
                <div
                  className={`border border-${theme} border-radius p-4 custom-card me-24`}
                >
                  <p className="weight-500">
                    {timeRange.text === 'today'
                      ? i18n.t(timeRange.title)
                      : timeRange.text === 'custom'
                        ? i18n.t('text.selected_period')
                        : `${i18n.t('text.last')} ${i18n.t(timeRange.title)}`}
                  </p>
                  <h5 className="ph-no-capture">
                    {analytics?.total_observation_count}
                  </h5>
                </div>

                <div
                  className={`border border-${theme} border-radius p-4 custom-card`}
                >
                  <p className="weight-500">
                    {timeRange.text === 'today'
                      ? i18n.t('dropdown.daylist.yesterday')
                      : timeRange.text === 'custom'
                        ? i18n.t('text.previous_period')
                        : `${i18n.t('text.previous')} ${i18n.t(timeRange.title)}`}
                  </p>
                  <h5 className="ph-no-capture">
                    {analytics?.total_observation_previous_period}
                  </h5>
                </div>
              </div>

              <div className={`border border-${theme} border-radius p-4 mb-24`}>
                <h6 className="mb-16">{i18n.t('heatmap.title')}</h6>
                <Image
                  className="border-radius add-cursor ph-no-capture"
                  width="100%"
                  src={heatMapImage}
                  onClick={() => setShowImageModal(true)}
                />
              </div>
            </Col>
            <Col md={5}>
              <div className={`border border-${theme} border-radius p-4`}>
                <h6 className="mb-16">{i18n.t('last_observations.title')}</h6>
                {observations.length !== 0 &&
                  observations.map((observation: ObservationProp) => (
                    <ObservationItem
                      onImageClick={(selected: ObservationProp) => {
                        setSelectedObservation(selected);
                        setShowModal(true);
                      }}
                      key={observation.requestId}
                      observation={observation}
                    />
                  ))}
              </div>
            </Col>
          </>
        )}
      </Row>

      {analytics && (
        <ShowImageModal
          imageUrl={heatMapImage}
          showModal={showImageModal}
          onCloseClick={() => setShowImageModal(false)}
        />
      )}

      {featureFlags.observations.show_extended_quick_view ? (
        <ObservationModal
          showModal={showModal}
          observation={selectedObservation}
          onCloseClick={() => setShowModal(false)}
        />
      ) : (
        <ObservationModalDeprecated
          observation={selectedObservation}
          showModal={showModal}
          onCloseClick={() => setShowModal(false)}
        />
      )}
    </>
  );
};
