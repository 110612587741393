import i18n from 'i18next';
import React, { PropsWithChildren } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Background from '../../assets/images/login-background.png';
import logoDark from '../../assets/logo-dark.svg';
import logoLight from '../../assets/logo-light.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { GRAY_LIGHT } from '../../scss/colors';
import { spacing } from '../../scss/spacing';
import { BWButton } from '../elements/BWButton';

export function LoginContainer({ children }: PropsWithChildren) {
  const { theme } = useThemeContext();

  return (
    <Row
      className="g-0"
      style={{
        height: '100vh',
        backgroundColor: GRAY_LIGHT,
      }}
    >
      <Col
        sm={12}
        md={6}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '360px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Image
            width="50%"
            src={theme === 'light' ? logoDark : logoLight}
            style={{ marginBottom: spacing(11) }}
          />

          {children}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: spacing(6),
          }}
        >
          <Link
            to="/#"
            className={`${theme === 'dark' ? 'white-color' : 'dark-color'}`}
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
              e.preventDefault();
              window._hsp.push(['showBanner']);
            }}
          >
            <BWButton
              title={i18n.t('footer.cookie_settings')}
              variant="transparent"
              style={{ padding: 0 }}
              trackingContext={{
                action: 'open_cookie_settings',
              }}
            />
          </Link>
          <Link
            to="https://www.buddywise.co/data-privacy"
            className={`${theme === 'dark' ? 'white-color' : 'dark-color'}`}
            style={{ textDecoration: 'none' }}
          >
            <BWButton
              title={i18n.t('footer.privacy_policy')}
              variant="transparent"
              style={{ padding: 0 }}
            />
          </Link>
        </div>
      </Col>
      <Col
        md={6}
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
    </Row>
  );
}
