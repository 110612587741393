/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CameraLink } from './CameraLink';
import { DisplayImage } from './DisplayImage';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/bar.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { Camera } from '../../hooks/graphql/camera';
import { SECONDARY_COLOR, SECONDARY_COLOR_RGB } from '../../scss/colors';
import { spacing } from '../../scss/spacing';
import { CameraStatusIcon } from '../camera/CameraStatusIcon';
import { BWButton } from '../elements/BWButton';
import { CameraSettingsModal } from '../modal/CameraSettingsModal';

interface Props {
  cameras: Array<Camera>;
  filterBySearch: string;
  renderListCol: number;
}

export function CameraList({ cameras, filterBySearch, renderListCol }: Props) {
  const { user, featureFlags } = useAuthContext();

  const [camera, setCamera] = useState<Camera | null>(null);

  const isAdmin = user?.isAdmin || false;
  const showDetailsPage = featureFlags.cameras.show_details_page;

  return (
    <Row
      className="camera"
      css={css`
        .camera-item-container {
          width: 100%;
          height: 100%;
          position: relative;
          border-radius: ${spacing(2)};
          overflow: hidden;

          .camera-item {
            position: relative;

            .camera-status {
              position: absolute;
              display: flex;
              flex-direction: row;
              align-items: center;
              bottom: 0;
              left: 0;
              background-color: rgba(${SECONDARY_COLOR_RGB}, 0.9);
              color: white;
              padding: ${spacing(1)} ${spacing(3)};
              margin: ${spacing(2)};
              z-index: 1;
            }
          }

          .front,
          .back {
            backface-visibility: hidden;
            overflow: hidden;
            height: 100%;
            width: 100%;
          }

          .back {
            position: absolute;
            top: 0;
            left: 0;
            background-color: ${SECONDARY_COLOR};
            visibility: hidden;
            opacity: 0;
            transition:
              visibility 0s,
              opacity 0.15s linear;
            display: flex;
            flex-direction: column;
            color: white;
            justify-content: center;
            align-items: center;

            p {
              margin-bottom: ${spacing(2)};
              display: flex;
              align-items: center;
            }

            .buttons {
              display: flex;

              .btn-transparent {
                display: flex;
                flex-direction: column;
                height: auto;
                justify-content: center;
                align-items: center;
                padding: ${spacing(2)};
                text-decoration: none;

                &:hover {
                  color: white !important;

                  svg {
                    path {
                      stroke: white;
                    }
                  }
                }

                svg {
                  margin: 0 0 ${spacing(2)} 0 !important;
                }
              }
            }
          }

          &:hover .back {
            visibility: visible;
            opacity: 1;

            img {
              position: absolute;
              top: 0;
              left: 0;
              padding: 1rem;
            }
          }
        }
      `}
    >
      {[...cameras]
        .sort((a: Camera, b: Camera) => a.name.localeCompare(b.name))
        .filter(
          (item) =>
            item.name.toLowerCase().indexOf(filterBySearch.toLowerCase()) !==
            -1,
        )
        .map((item: Camera) => (
          <Col
            key={item.id}
            md={renderListCol}
            className="camera-item-cell"
            style={{
              marginBottom: `${spacing(6)}`,
            }}
          >
            <div className="camera-item-container">
              <div className="front camera-item">
                <p
                  className="camera-status rounded weight-500 ph-no-capture"
                  style={{ gap: `${spacing(2)}` }}
                >
                  <span>{item.name}</span>
                  {item.status !== null && (
                    <CameraStatusIcon status={item.status} />
                  )}
                </p>
                <DisplayImage id={item.id} cameraPerRow={renderListCol} />
              </div>

              <div className="back">
                {showDetailsPage ? (
                  <CameraLink link={`/camera/${item.id}`}>
                    {i18n.t('page.cameras.go_to_details')}
                    <ArrowRightIcon />
                  </CameraLink>
                ) : (
                  <div className="buttons">
                    <BWButton
                      className="button-with-loader mx-12"
                      variant="transparent"
                      title={i18n.t('button.settings')}
                      type="button"
                      icon={SettingsIcon}
                      disabled={!isAdmin}
                      onClick={() => {
                        setCamera(item);
                      }}
                    />

                    <Link
                      to={`/camera-analytics/${item.id}`}
                      className="me-2 btn btn-transparent"
                    >
                      <AnalyticsIcon />
                      {i18n.t('button.analytics')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Col>
        ))}

      {camera && (
        <CameraSettingsModal
          cameraId={camera.id}
          show
          onCloseClick={() => setCamera(null)}
        />
      )}
    </Row>
  );
}
