import * as Sentry from '@sentry/react';

let isInitialized = false;

export function initSentry() {
  const dsn = process.env.REACT_APP_SENTRY_DSN;

  if (dsn && process.env.REACT_APP_SENTRY_DISABLE !== 'true') {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn,
      integrations: [new Sentry.BrowserTracing({})],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    isInitialized = true;
  }
}

export function captureExceptionInSentry(error: Error) {
  if (!isInitialized) return;

  Sentry.captureException(error);
}
