/* eslint-disable unused-imports/no-unused-vars */
import i18n from 'i18next';
import React, { useCallback, useState } from 'react';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useConfirmationModal } from '../../../hooks/modal';
import { SettingsBlock } from '../../modal/camera-settings/SettingsBlock';
import { ConfirmationModal } from '../../modal/ConfirmationModal';

type Props = {
  onCameraRemoved: () => void;
};

export function RemoveCameraBlock({ onCameraRemoved }: Props) {
  const { gqlCamera, removeCamera } = useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);

  const { confirmationModal, openConfirmationModal, closeConfirmationModal } =
    useConfirmationModal();

  const doRemoveCamera = useCallback(async () => {
    if (!gqlCamera) return;

    setLoading(true);
    const success = await removeCamera({
      id: gqlCamera.id,
      ip_address: gqlCamera.ip_address,
    });
    if (success) {
      onCameraRemoved();
    }
    setLoading(false);
  }, [gqlCamera, onCameraRemoved, removeCamera]);

  const handleRemoveCamera = useCallback(() => {
    openConfirmationModal({
      title: i18n.t('modal.delete_camera.title'),
      body: i18n.t('modal.delete_camera.body'),
      buttonType: 'danger',
      doneText: i18n.t('modal.delete_observation.yes'),
      cancelText: i18n.t('modal.delete_observation.no'),
      onClose: (reason) => {
        if (reason === 'confirm') {
          doRemoveCamera();
        }
        closeConfirmationModal();
      },
    });
  }, [closeConfirmationModal, doRemoveCamera, openConfirmationModal]);

  return (
    <>
      <SettingsBlock
        title={i18n.t('camera.remove_camera.title')}
        description={i18n.t('camera.remove_camera.body')}
        buttonLabel={i18n.t('button.remove_camera')}
        buttonLoading={loading}
        onClick={handleRemoveCamera}
        withoutHr
      />
      <ConfirmationModal options={confirmationModal} />
    </>
  );
}
