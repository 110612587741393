import { ApolloError } from '@apollo/client';
import i18n from 'i18next';
import React, { useState } from 'react';
import { Col, Table } from 'react-bootstrap';
import { ObservationRow } from './ObservationRow';
import { ReactComponent as Arrow } from '../../assets/icons/up-arrow.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  DaysListProps,
  GroupingItemProp,
} from '../../typescript/grouping/grouping-observation';

interface Props {
  groupData: Array<GroupingItemProp>;
  error: ApolloError | undefined;
  selectedDay: DaysListProps;
}

export function ObservationsOverview({ groupData, error, selectedDay }: Props) {
  const { theme } = useThemeContext();

  // States
  const [listOrder, setListOrder] = useState<boolean>(true);

  return (
    <Col lg={12} className="mb-32">
      <h6 className="mb-20">
        {i18n.t('group_observations.observations_overview')}
      </h6>
      <Table
        responsive={`sm alerts-table overflow-hidden border border-${theme} border-radius`}
        hover
        variant={theme}
      >
        <thead className="weight-500">
          <tr>
            <td>{i18n.t('td.scenario')}</td>
            <td width="60%">{i18n.t('td.camera_name')}</td>
            <td>{i18n.t('td.trend')}</td>
            <td onClick={() => setListOrder(!listOrder)}>
              <div className="d-flex align-items-center">
                {i18n.t('td.count')}
                <Arrow className={`ms-1 icon ${!listOrder && 'rotate-180'}`} />
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {!error &&
            groupData
              .sort((a, b) => {
                if (listOrder) {
                  return b.count - a.count;
                }
                return a.count - b.count;
              })
              .map((observation: GroupingItemProp) => (
                <ObservationRow
                  key={observation.id}
                  observation={observation}
                  selectedDay={selectedDay}
                />
              ))}
        </tbody>
      </Table>
    </Col>
  );
}
