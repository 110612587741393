import axios from 'axios';

export const baseURL = process.env.REACT_APP_DEMO_CAMERAS_URL;

const request = axios.create({
  baseURL,
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getAvailableVideos = async () => {
  const response = await request
    .get('/stream/get_available_videos')
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const startVideos = async (data: string | number | boolean) => {
  const response = await request
    .post(`stream/start?video_name=${data}&duration=600`)
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};
