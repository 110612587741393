/* eslint-disable import/no-mutable-exports */
import { Cookies } from 'react-cookie';
import { isNumber } from '../utils/typeUtils';

// TODO: Remove this when we have a proper way to get the siteId
export let globalSiteId: string =
  localStorage.getItem('siteId') || new Cookies().get('siteId') || '';

export function setGlobalSiteId(value: number | null) {
  globalSiteId = isNumber(value) ? String(value) : '';
}
