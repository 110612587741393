import i18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { EmptyGroupList } from '../components/default-component/EmptyGroupList';
import { CSVDownloader } from '../components/elements/CSVDownloader';
import {
  CustomDropdown,
  mapScenarioToDropdownProps,
} from '../components/elements/CustomDropdown';
import { Loader } from '../components/elements/Loader';
import { ObservationsOverview } from '../components/group/ObservationsOverview';
import { ObservationTrends } from '../components/group/ObservationTrends';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useScenarioContext } from '../contextapi/ScenarioProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import {
  GetGroupsQueryVariables,
  useGetGroupsQuery,
} from '../hooks/graphql/groups';
import {
  getScenarioTitle,
  mapToScenarioName,
  Scenario,
} from '../lib/features/scenario';
import {
  DaysListProps,
  GroupingItemProp,
} from '../typescript/grouping/grouping-observation';
import { captureExceptionInSentry } from '../utils/Sentry';

type ScenarioAndActive = [Scenario, boolean];

export const GroupObservationsPage = () => {
  const { theme } = useThemeContext();
  const { user, featureFlags } = useAuthContext();
  const { customerScenarios } = useScenarioContext();
  const { timeRange } = useTimeRangeContext();

  const [allScenarioList, setAllScenarioList] = useState<ScenarioAndActive[]>(
    [],
  );
  const [selectAllScenario, setSelectAllScenario] = useState<boolean>(false);

  const [variables, setVariables] = useState<GetGroupsQueryVariables>({
    from: timeRange.value,
    until: timeRange.end,
  });

  const showExportCsv = featureFlags.observations.show_export_csv || false;

  // GraphQL
  const { loading, error, data, refetch } = useGetGroupsQuery(variables);

  useEffect(() => {
    if (error) {
      captureExceptionInSentry(error);
    }
  }, [error]);

  useEffect(() => {
    if (customerScenarios) {
      const scenarios = customerScenarios.map((scenario) => ({
        ...scenario,
        title: getScenarioTitle(scenario),
      }));
      setSelectAllScenario(true);
      setAllScenarioList(scenarios.map((s) => [s, true]));
    }
  }, [customerScenarios]);

  useEffect(() => {
    refetch();
  }, [refetch, variables]);

  if (loading) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  const groupData =
    data?.observation_group_statistics_list.filter(
      (observation: GroupingItemProp) => observation.count > 0,
    ) || [];

  const totalCount = groupData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0,
  );

  const previousCount = groupData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.previous_count,
    0,
  );

  async function updateVariables(
    variable: GetGroupsQueryVariables,
    timeRange: DaysListProps,
    scenarioIds?: Array<number>,
  ) {
    if (scenarioIds) {
      setVariables({ ...variable, customer_scenario_label_ids: scenarioIds });
    } else {
      setVariables(variable);
    }
  }

  function generateGreetings() {
    const currentHour = Number(moment().format('HH'));
    if (currentHour >= 6 && currentHour < 12) {
      return i18n.t('text.good_morning');
    }
    if (currentHour >= 12 && currentHour < 15) {
      return i18n.t('text.good_afternoon');
    }
    if (currentHour >= 15 && currentHour < 20) {
      return i18n.t('text.good_evening');
    }
    if (currentHour >= 20 || currentHour < 6) {
      return i18n.t('text.good_night');
    }
    return '';
  }

  const scenarioNames = groupData.map(
    (observation) => observation.scenario_names.map(mapToScenarioName)[0],
  );
  const filteredScenario = customerScenarios
    .filter((element) => scenarioNames.includes(element.name))
    .map((item) => item.id);

  const cameraIds = [
    ...new Set(
      groupData.flatMap((entry) => entry.cameras.map((camera) => camera.id)),
    ),
  ];

  return (
    <>
      <div className="d-flex mt-24">
        <div className="mb-32 flex-1 d-flex align-items-center">
          <h5 className="m-0 ph-no-capture">
            {generateGreetings()},{' '}
            {user?.username ? user?.username : user?.email} 👋
          </h5>
        </div>
        <div className="mb-32 d-flex align-items-center justify-content-end">
          <TimeRangeDropdown
            onChange={(timeRange: DaysListProps) => {
              const updateDay = { ...variables };
              updateDay.from = timeRange.value;
              updateDay.until = timeRange.isDatePicker
                ? timeRange.end
                : moment().unix();

              updateVariables(updateDay, timeRange);
            }}
          />
          <CustomDropdown
            variant="scenario"
            showCheckboxes
            className="rounded-pill ms-12"
            onSelectedAll={(value, fromChild) => {
              setSelectAllScenario(value);
              if (!fromChild) {
                const selectAll = customerScenarios.map((scenario) => ({
                  ...scenario,
                  title: getScenarioTitle(scenario),
                }));
                setAllScenarioList(selectAll.map((s) => [s, value]));
              }
            }}
            selectedAll={selectAllScenario}
            title={`${
              selectAllScenario
                ? i18n.t('button.show_all_scenarios')
                : i18n.t('button.no_of_selected_scenario')
            }`}
            selectAllTitle={`${i18n.t('button.select_all')}`}
            dropdownData={allScenarioList.map(mapScenarioToDropdownProps)}
            onClick={({ scenario }) => {
              const selectAll = allScenarioList.map(([item, active]) => {
                if (item.name === scenario?.name) {
                  return [item, !active] satisfies ScenarioAndActive;
                }
                return [item, active] satisfies ScenarioAndActive;
              });
              setAllScenarioList(selectAll);
            }}
            confirmTitle={`${i18n.t('button.confirm')}`}
            showConfirmButton
            onConfirm={(value) => {
              const scenarioIds = value
                .filter((item) => item.isChecked)
                .map((item) => item.scenario?.id || 0);
              updateVariables(variables, timeRange, scenarioIds);
            }}
          />
          {showExportCsv && (
            <CSVDownloader
              disable={groupData.length === 0}
              scenarios={filteredScenario}
              start_time={timeRange.value}
              end_time={timeRange.end}
              camera_ids={cameraIds}
            />
          )}
        </div>
      </div>

      <Row className="group-observations">
        {groupData.length === 0 && <EmptyGroupList />}
        {groupData.length !== 0 && (
          <>
            <Col md={12} className="mb-20">
              <h6 className="m-0">
                {i18n.t('group_observations.total_observations')}
              </h6>
            </Col>
            <Col md={6} className="mb-32">
              <div
                className={`p-3 border border-${theme} border-radius d-flex flex-column justify-content-between flex-1`}
              >
                <p className="mb-1 light-gray">
                  {timeRange.text === 'today'
                    ? i18n.t(timeRange.title)
                    : timeRange.text === 'custom'
                      ? i18n.t('text.selected_period')
                      : `${i18n.t('text.last')} ${i18n.t(timeRange.title)}`}
                </p>
                <h4 className="m-0 weight-400 ph-no-capture">{totalCount}</h4>
              </div>
            </Col>
            <Col md={6} className="mb-32">
              <div
                className={`p-3 border border-${theme} border-radius d-flex flex-column justify-content-between flex-1`}
              >
                <p className="mb-1 light-gray">
                  {timeRange.text === 'today'
                    ? i18n.t('dropdown.daylist.yesterday')
                    : timeRange.text === 'custom'
                      ? i18n.t('text.previous_period')
                      : `${i18n.t('text.previous')} ${i18n.t(timeRange.title)}`}
                </p>
                <h4 className="m-0 weight-400 ph-no-capture">
                  {previousCount}
                </h4>
              </div>
            </Col>

            <ObservationsOverview
              groupData={groupData}
              error={error}
              selectedDay={timeRange}
            />

            <ObservationTrends groupData={groupData} />
          </>
        )}
      </Row>
    </>
  );
};
