/* eslint-disable import/no-mutable-exports */
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { isNotNull } from '../utils/typeUtils';

export const GRAPHQL_API_URL = `${process.env.REACT_APP_GRAPHQL_URL}/v1/graphql`;

const cache = new InMemoryCache({
  typePolicies: {
    ListObservationGroupsOutput: {
      fields: {
        cameras: {
          merge(_existing, incoming) {
            return [...incoming];
          },
        },
        timeseries: {
          merge(_existing, incoming) {
            return [...incoming];
          },
        },
      },
    },
    observations: {
      fields: {
        observation_users: {
          merge(_existing, incoming) {
            return [...incoming];
          },
        },
      },
    },
  },
});

export function createApolloClient(
  userToken: string | null,
  siteId: number | null,
) {
  const link = createHttpLink({
    uri: GRAPHQL_API_URL,
    headers: {
      authorization: userToken || '',
      ...(isNotNull(siteId) && { 'X-Site-Id': String(siteId) }),
    },
  });

  return new ApolloClient({
    link,
    cache,
  });
}
