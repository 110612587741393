/** @jsxImportSource @emotion/react */
import i18n from 'i18next';
import React, { useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { CameraList } from '../components/cameras/CameraList';
import { EmptyObservationsList } from '../components/default-component/EmptyObservationsList';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { buttonCss, BWButton } from '../components/elements/BWButton';
import { dropdownCss } from '../components/elements/CustomDropdown';
import { InputField } from '../components/elements/InputField';
import { Loader } from '../components/elements/Loader';
import { PageTitle } from '../components/elements/PageTitle';
import { AddCameraModal } from '../components/modal/AddCameraModal';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTrackEvent } from '../hooks/tracking';
import { RenderList, RenderListProps } from '../typescript/camera/camera';

export const CamerasPage = () => {
  // Context
  const { theme } = useThemeContext();
  const { gqlCameras, isCameraListLoading } = useCameraContext();
  const trackEvent = useTrackEvent();

  // State
  const [renderList] = useState<Array<RenderListProps>>(RenderList);
  const storageRenderListCount = localStorage.getItem('cameraPerRow');
  const defaultRenderListCount: RenderListProps = storageRenderListCount
    ? JSON.parse(storageRenderListCount)
    : { value: 3, colValue: 4 };

  const [renderListCount, setRenderListCount] = useState<RenderListProps>(
    defaultRenderListCount,
  );
  const [filterBySearch, setFilterBySearch] = useState<string>('');

  // Modal
  const [showAddCameraModal, setShowAddCameraModal] = useState<boolean>(false);

  const handleCameraSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBySearch(e.target.value);
  };

  const handleAddCameraModal = () => {
    setShowAddCameraModal(!showAddCameraModal);
  };

  return (
    <div className="main cameras">
      <Row>
        <Col md={12}>
          <Breadcrumb title={`${i18n.t('dashboard.title')}`} toUrl="/" />
          <PageTitle title={i18n.t('cameras.title')} />
        </Col>
        <Col
          md={12}
          className="d-flex justify-content-between align-items-center"
        >
          <InputField
            type="text"
            className={`mb-12 input-${theme}`}
            controlId="search"
            placeholder={`${i18n.t('input.search.placeholder')}`}
            onChange={handleCameraSearch}
          />
          <div className="d-flex align-items-center">
            <BWButton
              className="button-with-loader"
              variant="primary"
              title={i18n.t('button.add_new_camera')}
              onClick={handleAddCameraModal}
              trackingContext={{
                action: 'add_new_camera',
              }}
            />

            <Dropdown css={dropdownCss}>
              <Dropdown.Toggle
                className="rounded-pill ms-12"
                variant={`outline-${theme}`}
                css={buttonCss}
              >
                {`${renderListCount ? renderListCount.value : 3} ${i18n.t('button.camera_per_row')}`}
              </Dropdown.Toggle>

              <Dropdown.Menu variant={theme}>
                {renderList.map((item) => (
                  <Dropdown.Item
                    key={item.value}
                    onClick={() => {
                      trackEvent('dropdown_select', {
                        action: 'change_camera_per_row',
                        dropdown_value: item.value,
                      });
                      localStorage.setItem(
                        'cameraPerRow',
                        JSON.stringify(item),
                      );
                      setRenderListCount(item);
                    }}
                  >
                    {`${item.value} Cameras`}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>

        <Col md={12} className="mt-32">
          {isCameraListLoading && (
            <div className="vh-100 d-flex justify-content-center align-items-center">
              <Loader main />
            </div>
          )}

          {isCameraListLoading && gqlCameras.length !== 0 && (
            <EmptyObservationsList />
          )}

          {gqlCameras.length !== 0 && (
            <CameraList
              renderListCol={renderListCount.colValue}
              cameras={gqlCameras}
              filterBySearch={filterBySearch}
            />
          )}
        </Col>
      </Row>

      <AddCameraModal
        show={showAddCameraModal}
        onCloseClick={() => setShowAddCameraModal(!showAddCameraModal)}
      />
    </div>
  );
};
