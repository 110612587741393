/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

import i18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, FormCheck, Table } from 'react-bootstrap';
import { FilterModal } from './FilterModal';
import { ObservationTableRow } from './ObservationTableRow';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as LargeDeleteIcon } from '../../assets/icons/large-delete.svg';
import { ReactComponent as Arrow } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ObservationsSetInput } from '../../graphql/mutations';
import { WhereQueryProps } from '../../graphql/queries/observation';
import {
  GetObservationsQueryBuilder,
  ObservationsListVariant,
} from '../../hooks/graphql/observations';
import { ModalOpenOptions, useConfirmationModal } from '../../hooks/modal';
import { useAddBookmarks } from '../../hooks/observations';
import { useTableAction } from '../../hooks/table-actions';
import {
  isReportFalseFormData,
  ModalFormData,
} from '../../typescript/components/modal';
import { TimePeriod } from '../../typescript/datetime';
import { ObservationProp } from '../../typescript/observation/observation';
import { isNumber } from '../../utils/typeUtils';
import { EmptyBookmarkList } from '../default-component/EmptyBookmarkList';
import { EmptyCameraObservations } from '../default-component/EmptyCameraObservations';
import { EmptyObservationsList } from '../default-component/EmptyObservationsList';
import { BWButton } from '../elements/BWButton';
import { CSVDownloader } from '../elements/CSVDownloader';
import { CustomDropdown } from '../elements/CustomDropdown';
import { CustomPagination } from '../elements/CustomPagination';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { ObservationModal } from '../modal/ObservationModal';
import { ObservationModalDeprecated } from '../modal/ObservationModalDeprecated';

const PAGE_LIMIT_CHOICES = [10, 20, 50, 100, 200];

type Props = {
  variant: ObservationsListVariant;
  buildGetObservationsQuery: GetObservationsQueryBuilder;
  observations: ObservationProp[];
  filter: WhereQueryProps;
  limit: number;
  pageNumber: number;
  totalPages: number;
  loading?: boolean;
  assignee?: string;

  selectedCameraIds?: Array<number>;
  selectedScenarioIds?: Array<number>;
  timePeriod?: TimePeriod;

  hideCameraColumn?: boolean;

  showCameraFilters?: boolean;
  showScenarioFilters?: boolean;
  hideDateFilter?: boolean;
  isOrder: boolean;
  setIsOrder: React.Dispatch<React.SetStateAction<boolean>>;

  onSensitiveObservation: (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => void;
  onFilterApplied: (
    filter: WhereQueryProps,
    listLimit: number,
    pageNo: number,
  ) => void;
  onUpdateObservation: (
    observationIds: Array<number>,
    value: ObservationsSetInput,
  ) => void;
  onDeleteObservation: (observationIds: Array<number>) => void;
};

export function ObservationsTable({
  variant,
  buildGetObservationsQuery,
  observations,
  loading = true,
  assignee,
  filter,
  limit,
  pageNumber,
  totalPages,
  selectedCameraIds,
  selectedScenarioIds,
  timePeriod,
  hideCameraColumn = false,
  showCameraFilters = false,
  showScenarioFilters = false,
  hideDateFilter = false,
  isOrder,
  setIsOrder,
  onSensitiveObservation,
  onFilterApplied,
  onUpdateObservation,
  onDeleteObservation,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { user, featureFlags } = useAuthContext();
  const { dropdownFilteredList } = useTableAction();
  const [checkedObservations, setCheckedObservations] = useState<
    Array<ObservationProp>
  >([]);
  const [selectedObservation, setSelectedObservation] =
    useState<ObservationProp>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [checked, setChecked] = useState(false);

  // Show Observation
  const [showModal, setShowModal] = useState(false);

  // Confirmation Modal
  const { confirmationModal, openConfirmationModal, closeConfirmationModal } =
    useConfirmationModal();
  const [buttonName, setButtonName] = useState<string | null>(null);

  // Filter Modal
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  // Pagination
  const handleClose = () => {
    setSelectedObservation(undefined);
    setSelectedIndex(undefined);
    setShowModal(false);
  };

  const [addObservationBookmarks] = useAddBookmarks();

  useEffect(() => {
    setCheckedObservations((checkedObservations) =>
      checkedObservations.filter((observation) =>
        observations.find((item) => item.id === observation.id),
      ),
    );
  }, [observations]);

  const observationIds = checkedObservations.map(
    (observation) => observation.id,
  );

  const handleShowConfirmation = (
    isTrue: boolean,
    buttonName: string,
    observationIds: number[],
    formData?: ModalFormData,
  ) => {
    if (isTrue) {
      switch (buttonName) {
        case 'report':
          if (isReportFalseFormData(formData)) {
            onUpdateObservation(observationIds, {
              is_false_positive: true,
              false_positive_reason: formData.reason,
              false_positive_comment: formData.comment,
              date_set_false_positive: moment()
                .utc()
                .format('YYYY-MM-DDTHH:mm:ssZ'),
            });
          }
          break;
        case 'delete':
          onDeleteObservation(observationIds);
          break;
        case 'acknowledged':
          onSensitiveObservation(observationIds, 'status', 'acknowledged');
          break;
        case 'resolve':
          onSensitiveObservation(observationIds, 'status', 'resolve');
          break;
        default:
          break;
      }
    }
    closeConfirmationModal();
  };

  const showConfirmationModal = (
    action: 'report' | 'delete' | 'acknowledged' | 'resolve',
    options: Omit<ModalOpenOptions, 'onClose' | 'type'>,
    observations: ObservationProp[],
  ) => {
    setButtonName(action);

    openConfirmationModal({
      type: action === 'report' ? 'report' : 'default',
      ...options,
      onClose: (reason, formData) => {
        handleShowConfirmation(
          reason === 'confirm',
          action,
          observations.map((observation) => observation.id),
          formData,
        );
      },
    });
  };

  const showDelete = featureFlags.observations.show_delete || false;
  const showExportCsv = featureFlags.observations.show_export_csv || false;

  return (
    <>
      <div className={`d-flex mb-24 action-header ${theme}`}>
        <BWButton
          title={i18n.t('button.report_false')}
          className="button-with-loader"
          variant="outline"
          icon={WarningIcon}
          type="button"
          disabled={checkedObservations.length === 0 || loading}
          loading={buttonName === 'report' && loading}
          trackingContext={{
            action: 'open_report_false_positive_modal',
          }}
          onClick={() => {
            showConfirmationModal(
              'report',
              {
                title: i18n.t('button.report_false'),
                buttonType: 'primary',
                doneText: i18n.t('button.send'),
                cancelText: i18n.t('button.cancel'),
              },
              checkedObservations,
            );
          }}
        />

        <CustomDropdown
          dropdownData={dropdownFilteredList}
          hideContextData
          className="ms-12"
          title={`${i18n.t('button.actions')}`}
          disabled={observationIds.length === 0}
          onClick={(selectValue) => {
            const { value, subMenu } = selectValue;
            if (value === 'bookmark') {
              const data = observationIds.map((item) => ({
                observation_id: `${item}`,
                user_id: user?.id || 0,
              }));
              addObservationBookmarks({
                variables: {
                  data,
                },
              });
            } else {
              onSensitiveObservation(
                observationIds,
                String(value),
                String(subMenu ? subMenu[0].value : ''),
              );
            }
          }}
          trackingContext={{
            action: 'observation_actions',
            context: {
              page: 'observation_list',
            },
          }}
        />

        {showDelete && (
          <BWButton
            title={i18n.t('button.delete')}
            className="button-with-loader ms-2"
            variant="outline"
            icon={DeleteIcon}
            type="button"
            disabled={checkedObservations.length === 0 || loading}
            loading={buttonName === 'delete' && loading}
            onClick={() => {
              showConfirmationModal(
                'delete',
                {
                  title: `${i18n.t('landing.modal.delete_observation.title')}`,
                  body: `${i18n.t('landing.modal.delete_observation.body')}`,
                  buttonType: 'danger',
                  doneText: i18n.t('modal.delete_observation.yes'),
                  cancelText: i18n.t('modal.delete_observation.no'),
                  icon: LargeDeleteIcon,
                },
                checkedObservations,
              );
            }}
          />
        )}

        <div className="filter-button ms-auto">
          <BWButton
            title={i18n.t('button.filters')}
            className="button-with-loader icon"
            variant="outline"
            type="button"
            icon={FilterIcon}
            onClick={() => setShowFilterModal(!showFilterModal)}
            trackingContext={{
              action: 'open_filters_modal',
            }}
          />
          {Object.keys(filter).length !== 0 && (
            <Badge className="rounded-circle" bg="danger">
              {Object.keys(filter).length}
            </Badge>
          )}
        </div>

        {showExportCsv && (
          <CSVDownloader
            disable={observations.length === 0}
            scenarios={
              selectedScenarioIds || filter.customer_scenario_label_id?._in
            }
            responder={
              filter.responder?._eq
                ? [filter.responder?._eq]
                : assignee
                  ? [assignee]
                  : undefined
            }
            priority={filter.priority?._eq ? [filter.priority?._eq] : undefined}
            status={filter.status?._eq ? [filter.status?._eq] : undefined}
            camera_ids={selectedCameraIds || filter.camera_id?._in}
            start_time={timePeriod?.from || filter.system_timestamp?._gte}
            end_time={timePeriod?.until || filter.system_timestamp?._lte}
            is_bookmarked={variant === 'bookmark'}
          />
        )}
      </div>
      {observations.length === 0 && (
        <div>
          {variant === 'camera' && <EmptyCameraObservations />}
          {variant === 'bookmark' && (
            <EmptyBookmarkList key="empty-list" className="pt-32" />
          )}
          {(variant === 'default' ||
            variant === 'group' ||
            variant === 'alert') && (
            <EmptyObservationsList key="empty-list" className="pt-32" />
          )}
        </div>
      )}
      {observations.length !== 0 && (
        <>
          <Table
            responsive={`sm alerts-table overflow-hidden border border-${theme} border-radius`}
            hover
            variant={theme}
          >
            <thead className="weight-500">
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <FormCheck
                      checked={checked}
                      className="me-12 check-box"
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setCheckedObservations(observations);
                        } else {
                          setCheckedObservations([]);
                        }
                        setChecked(e.currentTarget.checked);
                      }}
                    />
                    <div
                      onClick={() => {
                        setIsOrder(!isOrder);
                      }}
                      aria-hidden
                      className="d-flex align-items-center add-cursor"
                    >
                      {i18n.t('td.date')}
                      <Arrow
                        className={`ms-1 icon ${!isOrder && 'rotate-180'}`}
                      />
                    </div>
                  </div>
                </td>
                <td>{i18n.t('td.scenario')}</td>
                {!hideCameraColumn && <td>{i18n.t('td.camera_name')}</td>}
                <td>{i18n.t('td.assignee')}</td>
                <td>{i18n.t('td.status')}</td>
                <td>{i18n.t('td.snapshot')}</td>
                <td>{i18n.t('td.priority')}</td>
                <td>{i18n.t('td.action')}</td>
              </tr>
            </thead>
            <tbody>
              {observations.map(
                (observation: ObservationProp, index: number) => (
                  <ObservationTableRow
                    hideCameraColumn={hideCameraColumn}
                    selectedObservations={
                      checkedObservations.filter(
                        (item) => item.id === observation.id,
                      ).length !== 0
                    }
                    isObservationSelected={(selected: boolean, id: number) => {
                      if (selected) {
                        setCheckedObservations([
                          ...checkedObservations,
                          observation,
                        ]);
                      } else {
                        const withoutEl = checkedObservations.filter(
                          (elemet) => elemet.id !== id,
                        );
                        setCheckedObservations(withoutEl);
                        setChecked(false);
                      }
                    }}
                    onImageClick={(value) => {
                      setSelectedObservation(value);
                      setSelectedIndex(index);
                      setShowModal(true);
                    }}
                    key={observation.id}
                    observation={observation}
                    onSensitiveObservationAction={(id, actionType, value) => {
                      onSensitiveObservation([id], actionType, value);
                    }}
                    onReportFalseModal={(id) => {
                      showConfirmationModal(
                        'report',
                        {
                          title: i18n.t('button.report_false'),
                          body: '',
                          buttonType: 'primary',
                          doneText: i18n.t('button.send'),
                          cancelText: i18n.t('button.cancel'),
                        },
                        observations.filter((item) => item.id === id),
                      );
                    }}
                  />
                ),
              )}
            </tbody>
          </Table>
          <div className="table-bottom-actions">
            <CustomDropdown
              disabled={observationIds.length === 0}
              dropdownData={dropdownFilteredList}
              title={`${i18n.t('button.actions')}`}
              onClick={(selectValue) => {
                const { value, subMenu } = selectValue;
                onSensitiveObservation(
                  observationIds,
                  String(value),
                  String(subMenu ? subMenu[0].value : ''),
                );
              }}
            />
            <CustomPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={(pageNo: number) => {
                onFilterApplied(filter, limit, pageNo);
              }}
            />

            <CustomDropdown
              title={`${i18n.t('button.show_number_of_items').replace('{value}', String(limit))}`}
              dropdownData={PAGE_LIMIT_CHOICES.map((item: number) => ({
                title: item,
                value: item,
              }))}
              onClick={(item) => {
                onFilterApplied(filter, Number(item.value), 1);
              }}
            />
          </div>
        </>
      )}

      <FilterModal
        filters={filter}
        showModal={showFilterModal}
        showCameraFilters={showCameraFilters}
        showScenarioFilters={showScenarioFilters}
        hideDateFilter={hideDateFilter}
        onClose={() => setShowFilterModal(!showFilterModal)}
        onApply={(appliedFilters) => {
          onFilterApplied(appliedFilters, limit, 1);
        }}
      />

      <ConfirmationModal options={confirmationModal} />

      {featureFlags.observations.show_extended_quick_view ? (
        <ObservationModal
          showModal={showModal}
          observation={selectedObservation}
          onCloseClick={handleClose}
          isLoading={loading}
          paginationDefaults={{ pageNumber, limit }}
          buildGetObservationsQuery={buildGetObservationsQuery}
        />
      ) : (
        <ObservationModalDeprecated
          showModal={showModal}
          observation={selectedObservation}
          onCloseClick={handleClose}
          onNextPress={() => {
            const lastIndex = observations.length - 1;
            if (isNumber(selectedIndex)) {
              if (selectedIndex < lastIndex) {
                setSelectedObservation(observations[selectedIndex + 1]);
                setSelectedIndex(selectedIndex + 1);
              } else {
                handleClose();
              }
            }
          }}
          onBackPress={() => {
            if (isNumber(selectedIndex) && selectedIndex > 0) {
              setSelectedObservation(observations[selectedIndex - 1]);
              setSelectedIndex(selectedIndex - 1);
            }
          }}
          onFKeyPress={() => {
            if (!selectedObservation) return;

            showConfirmationModal(
              'report',
              {
                title: i18n.t('button.report_false'),
                body: '',
                buttonType: 'primary',
                doneText: i18n.t('button.send'),
                cancelText: i18n.t('button.cancel'),
              },
              [selectedObservation],
            );
          }}
          onAKeyPress={() => {
            if (!selectedObservation) return;

            showConfirmationModal(
              'acknowledged',
              {
                title: `${i18n.t('confirmation.modal.acknowledged_observation.title')}`,
                body: `${i18n.t('confirmation.modal.acknowledged_observation.body')}`,
                buttonType: 'primary',
                doneText: i18n.t('button.yes'),
                cancelText: i18n.t('button.back'),
              },
              [selectedObservation],
            );
          }}
          onRKeyPress={() => {
            if (!selectedObservation) return;

            showConfirmationModal(
              'resolve',
              {
                title: `${i18n.t('confirmation.modal.resolve_observation.title')}`,
                body: `${i18n.t('confirmation.modal.resolve_observation.body')}`,
                buttonType: 'primary',
                doneText: i18n.t('button.yes'),
                cancelText: i18n.t('button.back'),
              },
              [selectedObservation],
            );
          }}
          isLoading={loading}
          showShortcutInfo
        />
      )}
    </>
  );
}
