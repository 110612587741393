import { useQuery } from '@apollo/client';
import { GET_GROUPDATA } from '../../graphql/queries/groups';
import { GroupingItemProp } from '../../typescript/grouping/grouping-observation';

export type GetGroupsQueryVariables = {
  from: number;
  until: number;
  customer_scenario_label_ids?: number[];
};

type GetGroupsQueryResponse = {
  observation_group_statistics_list: GroupingItemProp[];
  observations_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const useGetGroupsQuery = (variables: GetGroupsQueryVariables) =>
  useQuery<GetGroupsQueryResponse, GetGroupsQueryVariables>(GET_GROUPDATA, {
    variables,
    fetchPolicy: 'network-only',
  });
