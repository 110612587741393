import { useMutation } from '@apollo/client';
import i18n from 'i18next';
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendOTP } from '../../apis/api-request';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { UPDATE_USER } from '../../graphql/mutations/user';
import { spacing } from '../../scss/spacing';
import { GqlCurrentUser } from '../../typescript/user/user';
import { BWButton } from '../elements/BWButton';
import { InputField } from '../elements/InputField';
import { OtpModal } from '../modal/OtpModal';

interface Props {
  user: GqlCurrentUser;
  editable?: boolean;
}

export function UserForm({ user, editable = false }: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { userToken, refetchUser } = useAuthContext();

  const phoneInputRef = useRef<string>();

  const [newUser, setNewUser] = useState<GqlCurrentUser>(user);

  const [loading, setLoading] = useState(false);
  const [loadingOtpModal, setLoadingOtpModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const [updateUser, onUpdateUser] = useMutation(UPDATE_USER);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setNewUser({ ...newUser, [name]: e.target.value });
  };

  const handlePhoneChange = (value: string) => {
    setNewUser({ ...newUser, phoneNo: value, phoneNoVerified: false });
    phoneInputRef.current = value;
  };

  useEffect(() => {
    setLoading(false);
    if (onUpdateUser.data) {
      toast.success(i18n.t('toast.success.user_update'));
      refetchUser();
    }
  }, [onUpdateUser.data, refetchUser]);

  const updateUserInfo = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    updateUser({
      variables: {
        id: newUser.id,
        name: newUser.username,
        title: newUser.title,
        phone_no: newUser.phoneNo,
      },
    });
  };

  const handleOtpModalOpen = async () => {
    setLoadingOtpModal(true);
    const response = await sendOTP(userToken, {
      phone_number: newUser.phoneNo,
    });
    setLoadingOtpModal(false);
    if (response.success) {
      setShowOtpModal(true);
    } else {
      toast.error(response.detail);
    }
  };

  return (
    <Form onSubmit={updateUserInfo} className="user-information">
      <InputField
        controlId="formFullName"
        className={`mb-12 input-${theme} ph-no-capture`}
        name="username"
        value={newUser.username}
        title={`${i18n.t('input.username.title')}`}
        placeholder={`${i18n.t('input.username.placeholder')}`}
        onChange={handleChange}
        disabled={!editable}
        required
      />

      <InputField
        controlId="formTitle"
        className={`mb-12 input-${theme} ph-no-capture`}
        name="title"
        value={newUser.title}
        title={`${i18n.t('input.user_title.title')}`}
        placeholder={`${i18n.t('input.user_title.placeholder')}`}
        onChange={handleChange}
        disabled={!editable}
        required
      />

      {editable && (
        <div className="mb-12 phone-verification">
          <Form.Label className="weight-500">{`${i18n.t('input.phoneNo.title')}`}</Form.Label>
          <InputGroup>
            <PhoneInput
              controlId="formPhoneNumber"
              className={`flex-1 input-${theme} ph-no-capture`}
              name="phoneNo"
              title={`${i18n.t('input.phoneNo.title')}`}
              international
              placeholder={`${i18n.t('input.phoneNo.placeholder')}`}
              value={newUser?.phoneNo}
              onChange={handlePhoneChange}
            />
            {(!user.phoneNoVerified || user.phoneNo !== newUser.phoneNo) && (
              <BWButton
                className="position-absolute end-0"
                variant="outline"
                type="button"
                title={!loadingOtpModal ? i18n.t('button.verify') : ''}
                disabled={loadingOtpModal}
                loading={loadingOtpModal}
                onClick={handleOtpModalOpen}
              />
            )}
          </InputGroup>
        </div>
      )}

      <InputField
        controlId="formEmail"
        className={`mb-12 input-${theme} ph-no-capture`}
        type="email"
        name="email"
        value={newUser.email}
        title={`${i18n.t('input.email.title')}`}
        placeholder={`${i18n.t('input.email.placeholder')}`}
        disabled
        readOnly
        required
      />

      {/* <InputField
        controlId="formRole"
        className={`input-${theme}`}
        value={newUser?.isAdmin ? 'Admin' : 'Non-Admin'}
        title={`${i18n.t('input.role.title')}`}
        placeholder={`${i18n.t('input.role.placeholder')}`}
        disabled
        readOnly
        required
      /> */}

      {editable && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: spacing(7),
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link
              to="/#"
              style={{
                height: 'auto',
                textDecoration: 'none',
              }}
              onClick={(e) => {
                e.preventDefault();
                window._hsp.push(['showBanner']);
              }}
            >
              <BWButton
                variant="link"
                style={{
                  color: theme === 'dark' ? 'white' : 'black',
                }}
                title={i18n.t('footer.cookie_settings')}
              />
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <BWButton
              title={i18n.t('button.cancel')}
              className="button-with-loader"
              variant="outline"
              type="button"
              onClick={() => navigate('/dashboard')}
            />
            <BWButton
              title={i18n.t('button.save')}
              className="button-with-loader ms-12"
              variant="primary"
              type="submit"
              disabled={loading}
              loading={loading}
            />
          </div>
        </div>
      )}

      <OtpModal
        show={showOtpModal}
        onClose={() => setShowOtpModal(false)}
        onVerify={async () => {
          await refetchUser();
          setShowOtpModal(false);
        }}
      />
    </Form>
  );
}
