import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Cookies } from 'react-cookie';
import { setGlobalSiteId } from '../apis/tokens';
import { FeatureFlagOverrides } from '../typescript/user/user';
import { isNumber, isString } from '../utils/typeUtils';

export type PersistentStorageContextType = {
  userToken: string | null;
  persistUserToken: (token: string | null) => void;
  siteId: number | null;
  persistSiteId: (siteId: number | null) => void;
  featureFlagOverrides: FeatureFlagOverrides | null;
  persistFeatureFlagOverrides: (overrides: FeatureFlagOverrides) => void;
};

const PersistentStorageContext = createContext<
  PersistentStorageContextType | undefined
>(undefined);

export const usePersistentStorageContext = () => {
  const context = useContext(PersistentStorageContext);
  if (!context) {
    throw new Error(
      'usePersistentStorageContext must be used within a PersistentStorageProvider',
    );
  }

  return context;
};

type Props = {
  children: React.ReactNode;
};

export const PersistentStorageProvider = ({ children }: Props) => {
  const [userToken, setUserToken] = useState<string | null>(
    localStorage.getItem('userToken'),
  );

  const persistUserToken = useCallback((token: string | null) => {
    setUserToken(token);
    if (isString(token) && token.length > 0) {
      localStorage.setItem('userToken', token);
    } else {
      localStorage.removeItem('userToken');
    }
  }, []);

  const [siteId, setSiteId] = useState<number | null>(
    localStorage.getItem('siteId')
      ? Number(localStorage.getItem('siteId'))
      : null,
  );

  const persistSiteId = useCallback((siteId: number | null) => {
    setSiteId(siteId);
    if (isNumber(siteId)) {
      localStorage.setItem('siteId', String(siteId));
    } else {
      localStorage.removeItem('siteId');
    }

    setGlobalSiteId(siteId);
    new Cookies().remove('siteId');
  }, []);

  const isDevToolsEnabled = process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true';
  const [featureFlagOverrides, setFeatureFlagOverrides] =
    useState<FeatureFlagOverrides | null>(
      isDevToolsEnabled
        ? JSON.parse(localStorage.getItem('featureFlagOverrides') || '{}')
        : null,
    );

  const persistFeatureFlagOverrides = useCallback(
    (overrides: FeatureFlagOverrides) => {
      if (isDevToolsEnabled) {
        setFeatureFlagOverrides(overrides);
        localStorage.setItem('featureFlagOverrides', JSON.stringify(overrides));
      }
    },
    [isDevToolsEnabled],
  );

  const context = useMemo(
    () => ({
      userToken,
      persistUserToken,
      siteId,
      persistSiteId,
      featureFlagOverrides,
      persistFeatureFlagOverrides,
    }),
    [
      userToken,
      persistUserToken,
      siteId,
      persistSiteId,
      featureFlagOverrides,
      persistFeatureFlagOverrides,
    ],
  );

  return (
    <PersistentStorageContext.Provider value={context}>
      {children}
    </PersistentStorageContext.Provider>
  );
};
