import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './scss/buddywise.scss';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { PersistentStorageProvider } from './contextapi/PersistentStorageProvider';
import { initPosthog, Posthog } from './utils/Posthog';
import { initSentry } from './utils/Sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

initPosthog();
initSentry();
window._hsp.push([
  'addPrivacyConsentListener',
  (consent) => {
    const isAnalyticsAllowed = consent.categories.analytics;
    if (isAnalyticsAllowed) {
      window._hsq.push(['doNotTrack']);
      Posthog.optInTracking();
    } else {
      Posthog.optOutTracking();
    }
  },
]);

root.render(
  <StrictMode>
    <PersistentStorageProvider>
      <App />
    </PersistentStorageProvider>
  </StrictMode>,
);
