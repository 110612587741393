import i18n from 'i18next';
import React, { useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import { AlertRow } from './AlertRow';
import { AlertsFilterModal } from './AlertsFilterModal';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as Arrow } from '../../assets/icons/up-arrow.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { WhereQueryProps } from '../../graphql/queries/alerts';
import { numberOfIncident } from '../../typescript/camera/camera';
import {
  AlertIncidents,
  AlertProps,
} from '../../typescript/smart-alert/smart-alert';
import { EmptyAlertsList } from '../default-component/EmptyAlertsList';
import { BWButton } from '../elements/BWButton';
import { CustomDropdown } from '../elements/CustomDropdown';
import { CustomPagination } from '../elements/CustomPagination';

type Props = {
  alerts: Array<AlertIncidents> | [];
  alertRules: Array<AlertProps> | [];
  filter: WhereQueryProps;
  limit: number;
  pageNumber: number;
  totalPages: number;

  isOrder: boolean;
  setIsOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryVar: React.Dispatch<React.SetStateAction<WhereQueryProps | null>>;

  onFilterApplied: (
    obj: WhereQueryProps | null,
    listLimit: number,
    pageNo: number,
  ) => void;
};
export function AlertsTable({
  alerts,
  alertRules,
  filter,
  limit,
  pageNumber,
  totalPages,
  isOrder,
  setIsOrder,
  setQueryVar,
  onFilterApplied,
}: Props) {
  // Context
  const { theme } = useThemeContext();

  // Filter Modal
  const [showFilter, setShowFilter] = useState<boolean>(false);

  return (
    <>
      <div className={`d-flex mb-24 action-header ${theme}`}>
        <div className="filter-button ms-auto">
          <BWButton
            title={i18n.t('button.filters')}
            className="button-with-loader icon"
            variant="outline"
            type="button"
            icon={FilterIcon}
            onClick={() => setShowFilter(!showFilter)}
          />
          {Object.keys(filter).length !== 0 && (
            <Badge className="rounded-circle" bg="danger">
              {Object.keys(filter).length}
            </Badge>
          )}
        </div>
      </div>
      {alerts.length === 0 && (
        <EmptyAlertsList key="empty-list" className="pt-32" />
      )}
      {alerts.length !== 0 && (
        <>
          <Table
            responsive={`sm alerts-table overflow-hidden border border-${theme} border-radius`}
            hover
            variant={theme}
          >
            <thead className="weight-500">
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <div
                      onClick={() => {
                        setIsOrder(!isOrder);
                      }}
                      aria-hidden
                      className="d-flex align-items-center add-cursor"
                    >
                      {i18n.t('td.date')}
                      <Arrow
                        className={`ms-1 icon ${!isOrder && 'rotate-180'}`}
                      />
                    </div>
                  </div>
                </td>
                <td>{i18n.t('td.alert')}</td>
                <td>{i18n.t('td.scenario')}</td>
                <td>{i18n.t('td.camera_name')}</td>
                <td>{i18n.t('td.action')}</td>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert) => (
                <AlertRow key={alert.id} alert={alert} />
              ))}
            </tbody>
          </Table>
          <div className="table-bottom-actions">
            <div />
            <CustomPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={(pageNo: number) => {
                onFilterApplied(filter, limit, pageNo);
              }}
            />

            <CustomDropdown
              title={`${i18n.t('button.show_number_of_items').replace('{value}', String(limit))}`}
              dropdownData={numberOfIncident.map((item: number) => ({
                title: item,
                value: item,
              }))}
              onClick={(item) => {
                onFilterApplied(filter, Number(item.value), 1);
              }}
            />
          </div>
        </>
      )}

      <AlertsFilterModal
        show={showFilter}
        alertRules={alertRules}
        data={filter}
        onCloseClick={() => setShowFilter(!showFilter)}
        onApplyClick={(obj) => {
          let whereQuery: WhereQueryProps | null = { ...filter };
          whereQuery = obj;

          setQueryVar(whereQuery);
          onFilterApplied(whereQuery, limit, 1);
        }}
      />
    </>
  );
}
