import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Cookies } from 'react-cookie';
import { z } from 'zod';

export type Theme = 'light' | 'dark';

type ThemeContextType = {
  changeTheme: (value: Theme) => void;
  theme: Theme;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useThemeContext = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
};

type Props = PropsWithChildren<{
  overrideTheme?: Theme;
}>;

export function ThemeProvider({ overrideTheme, children }: Props) {
  const storedTheme =
    localStorage.getItem('theme') || new Cookies().get('themeValue');
  const defaultTheme = z.enum(['light', 'dark']).safeParse(storedTheme).data;
  const [theme, setTheme] = useState(overrideTheme || defaultTheme || 'light');

  useEffect(() => {
    if (overrideTheme) {
      return;
    }

    if (theme !== defaultTheme || localStorage.getItem('theme') === null) {
      localStorage.setItem('theme', theme);
      new Cookies().remove('themeValue');
    }
  }, [overrideTheme, theme, defaultTheme]);

  const ThemeProviderValue = useMemo(
    () => ({
      changeTheme: (value: Theme) => {
        setTheme(value);
      },
      theme,
    }),
    [theme],
  );

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      <div className={theme}>{children}</div>
    </ThemeContext.Provider>
  );
}
