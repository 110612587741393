import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col, FormCheck, Modal, Row } from 'react-bootstrap';
import {
  getAvailableVideos,
  startVideos,
} from '../../../apis/demp-api-request';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { TitleAndValue } from '../../../typescript/observation/observation';
import { BWButton } from '../../elements/BWButton';

type Props = {
  show: boolean;
  onCloseClick: () => void;
  onNextClick: (name: string, ip: string) => void;
};

export function DemoVideos({ show = false, onCloseClick, onNextClick }: Props) {
  const { theme } = useThemeContext();
  const [videoNames, setVideoNames] = useState<Array<TitleAndValue>>([]);
  const [video, setVideo] = useState<TitleAndValue>();

  useEffect(() => {
    const subscribe = async () => {
      const videos = await getAvailableVideos();
      if (videos.data?.length !== 0) {
        const updatedList = videos.data.map((item: string) => {
          const replaceDash = item.replaceAll('_', ' ');
          const title =
            replaceDash.charAt(0).toUpperCase() + replaceDash.slice(1);
          return { title: `Demo: ${title}`, value: item };
        });
        setVideoNames(updatedList);
      }
      return videos;
    };
    if (show) {
      subscribe();
    }
  }, [show]);

  const startVideosStream = async () => {
    if (video) {
      const videos = await startVideos(video.value);
      onNextClick(video.title, videos.data);
    }
  };

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={show}
      onHide={onCloseClick}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.edit_scenarios.title')}</Modal.Title>
        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={onCloseClick}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <p className="mb-1 weight-600">
              {i18n.t('active_scenarios.title')}
            </p>

            {videoNames.map((item: TitleAndValue) => (
              <FormCheck
                key={`${item}`}
                label={`${item.title}`}
                name="group1"
                type="radio"
                onClick={() => setVideo(item)}
              />
            ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <BWButton
          title={i18n.t('button.back')}
          className="button-with-loader"
          variant="outline"
          type="button"
          onClick={onCloseClick}
        />
        <BWButton
          title={i18n.t('button.next')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={() => startVideosStream()}
        />
      </Modal.Footer>
    </Modal>
  );
}
