import { saveAs } from 'file-saver';
import i18n from 'i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from './Loader';
import { baseUrl } from '../../apis/api-csv';
import { globalSiteId } from '../../apis/tokens';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useTrackEvent } from '../../hooks/tracking';
import { SVGComponent } from '../../typescript/utils';

interface Props {
  className?: string;
  variant?: string;
  title?: string;
  buttonText?: string;
  icon?: SVGComponent;
}

export function BottomToast({
  className,
  variant,
  title,
  buttonText,
  icon: Icon,
}: Props) {
  const { userToken, setIsCSVStatus, isCSVStatus } = useAuthContext();
  const trackEvent = useTrackEvent();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const handleDownload = () => {
    setLoading(true);
    fetch(
      `${baseUrl}/api/v2/file-endpoint/download-file?file_type=CSV_EXPORT`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${userToken}`,
          ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
        },
      },
    )
      .then((response) => {
        const blob = response.blob();
        if (response.status === 404) {
          toast.error(`Error ${response.status}`);
          return null;
        }
        return blob;
      })
      .then((blob) => {
        if (blob) {
          saveAs(blob, 'export_csv.csv');
        }
      })
      .catch((error) => {
        console.error('Error downloading CSV:', error);
      })
      .finally(() => {
        setIsCSVStatus(null);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setCancelLoading(true);
    fetch(`${baseUrl}/api/v2/file-endpoint/cancel-file?file_type=CSV_EXPORT`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${userToken}`,
        ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
      },
    })
      .catch((error) => {
        console.error('Error downloading CSV:', error);
      })
      .finally(() => {
        setIsCSVStatus(null);
        setCancelLoading(false);
      });
  };

  return (
    <div className={`${className} bottom-toast bottom-toast-${variant}`}>
      {Icon && <Icon className="me-2" />}
      <span className="me-12">{title}</span>
      {isCSVStatus === 'done' && !cancelLoading && (
        <button
          type="button"
          className="bottom-toast-button"
          disabled={loading}
          onClick={() => {
            trackEvent('button_click', {
              action: 'download_csv',
            });
            handleDownload();
          }}
        >
          {!loading && (
            <>
              <DownloadIcon className="me-2" />
              {buttonText}
            </>
          )}
          {loading && <Loader className="bottom-toast-loader" />}
        </button>
      )}
      <button
        type="button"
        className="bottom-toast-button"
        disabled={cancelLoading}
        onClick={() => {
          trackEvent('button_click', {
            action: 'cancel_csv_download',
          });
          handleCancel();
        }}
      >
        {!cancelLoading && i18n.t('button.cancel')}
        {cancelLoading && <Loader className="bottom-toast-loader" />}
      </button>
    </div>
  );
}
