import i18n from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityObserver from 'react-visibility-observer';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { ObservationProp } from '../../typescript/observation/observation';
import { LazyLoadedImage } from '../elements/LazyLoadedImage';
import { ScenarioLabel } from '../elements/ScenarioLabel';

interface Props {
  observation: ObservationProp;
  onImageClick: (selectedAlert: ObservationProp) => void;
}

export function ObservationItem({ observation, onImageClick }: Props) {
  const { clientUsersList } = useAuthContext();
  const { getScenarioByName } = useScenarioContext();

  const [observationDetail, setObservationDetail] =
    useState<ObservationProp>(observation);

  const filteredScenario = getScenarioByName(
    observationDetail.customer_scenario_label.name,
  );

  const filteredAssignee = clientUsersList.filter(
    (item) => item.email === observation.responder,
  )[0];

  return (
    <>
      <div className="incidents-list">
        <VisibilityObserver triggerOnce threshold={0.2}>
          <LazyLoadedImage
            onImageClick={(udpatedObservation) => {
              onImageClick(udpatedObservation);
              setObservationDetail(udpatedObservation);
            }}
            observation={observationDetail}
          />
        </VisibilityObserver>
        <div className="content">
          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ minWidth: 70 }}>
              {i18n.t('td.created')}:
            </p>
            <p className="m-0 ph-no-capture">
              {moment
                .unix(observation?.system_timestamp)
                .format('MMM DD, HH:mm:ss')}
            </p>
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ minWidth: 70 }}>
              {i18n.t('td.scenario')}:
            </p>
            <p className="m-0">
              {!filteredScenario ? (
                i18n.t('td.scenario_not_found')
              ) : (
                <ScenarioLabel scenario={filteredScenario} />
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ minWidth: 70 }}>
              {i18n.t('td.assignee')}:
            </p>
            {observation.responder && filteredAssignee ? (
              <Link
                className="d-inline ph-no-capture"
                to={`/profile/${filteredAssignee.id}`}
              >
                {filteredAssignee.username || observation.responder}
              </Link>
            ) : (
              i18n.t('td.not_assigned')
            )}
          </div>
        </div>
      </div>

      <hr />
    </>
  );
}
