import React from 'react';
import { spacing } from '../../../scss/spacing';

type Props = {
  title: string | null;
  icon: JSX.Element;
};

export function ObservationDetail({ title, icon }: Props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: spacing(1) }}>
      {icon}
      <p style={{ margin: 0 }}>{title}</p>
    </div>
  );
}
