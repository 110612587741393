/* eslint-disable react/no-unused-prop-types */
import i18n from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { BWButton } from './BWButton';
import { generateFile } from '../../apis/api-csv';
import { ReactComponent as CSVIcon } from '../../assets/icons/csv-icon.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { useTrackEvent } from '../../hooks/tracking';

type Props = {
  type?: string;
  scenarios?: Array<number>;
  camera_ids?: Array<number>;
  responder?: Array<string>;
  status?: Array<string>;
  priority?: Array<string>;
  start_time?: number;
  end_time?: number;
  is_bookmarked?: boolean;
  limit?: number;
  disable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export function CSVDownloader({
  type,
  scenarios,
  camera_ids,
  responder,
  status,
  priority,
  start_time,
  end_time,
  is_bookmarked,
  limit,
  disable,
}: Props) {
  const { userToken, isCSVStatus, setIsCSVStatus } = useAuthContext();
  const { customerScenarios } = useScenarioContext();
  const [loader, setLoader] = useState(false);
  const trackEvent = useTrackEvent();

  return (
    <BWButton
      title={i18n.t('button.export_as_csv')}
      className="button-with-loader ms-12"
      variant="outline"
      icon={CSVIcon}
      onClick={async () => {
        const scenarioIds = customerScenarios.map((item) => item.id);
        setLoader(true);
        trackEvent('button_click', {
          action: 'initiate_csv_download',
        });
        const generate_file = await generateFile(userToken, {
          type: type || 'CSV_EXPORT',
          scenarios: scenarios || scenarioIds,
          camera_ids,
          responder,
          status,
          priority,
          limit,
          start_time,
          is_bookmarked,
          end_time: end_time || moment().unix(),
        });
        setLoader(false);
        if (generate_file.status === 200) {
          setIsCSVStatus('pending');
        }
      }}
      loading={loader}
      disabled={disable || loader || isCSVStatus !== null}
    />
  );
}
