/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useTrackEvent } from '../../hooks/tracking';
import { PRIMARY_COLOR, PRIMARY_COLOR_DARK } from '../../scss/colors';

type Props = PropsWithChildren<{
  link: string;
}>;

export const CameraLink = ({ link, children }: Props) => {
  const trackEvent = useTrackEvent();

  return (
    <Link
      to={link}
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
      `}
      onClick={() => {
        trackEvent('camera_card_click');
      }}
    >
      <span
        className="gap-2"
        css={css`
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $spacing4;
          background-color: transparent;
          color: ${PRIMARY_COLOR} !important;
          border: none !important;

          &:hover,
          &:focus,
          &:active {
            color: ${PRIMARY_COLOR_DARK} !important;

            svg {
              & path {
                fill: ${PRIMARY_COLOR_DARK};
              }
            }
          }
        `}
      >
        {children}
      </span>
    </Link>
  );
};
