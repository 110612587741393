import { useQuery } from '@apollo/client';
import { GET_USER } from '../../graphql/queries/user';
import { GqlCurrentUser } from '../../typescript/user/user';

type GetUserQueryResponse = {
  user: [GqlCurrentUser];
};

type GetUserQueryVariables = {
  includeUsers: boolean;
};

export const useGetUserQuery = (variables: GetUserQueryVariables) =>
  useQuery<GetUserQueryResponse, GetUserQueryVariables>(GET_USER, {
    variables,
    fetchPolicy: 'network-only',
  });
