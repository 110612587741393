/* eslint-disable unused-imports/no-unused-vars */
import i18n from 'i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { imageUpdate } from '../../../apis/api-request';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { SettingsBlock } from '../../modal/camera-settings/SettingsBlock';

export function ResetBackgroundImage() {
  const { userToken, onTokenSave } = useAuthContext();
  const { gqlCamera } = useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);

  const onUpdateImageCamera = async () => {
    if (!gqlCamera) return false;

    setLoading(true);
    const param = { id: gqlCamera.id, scenario: 'road-safety-eq' };
    const updateCameraImage = await imageUpdate(userToken, param);
    setLoading(false);

    if (updateCameraImage.status === 200) {
      toast.success(i18n.t('toast.success.site_is_verified'));
      return true;
    }
    if (updateCameraImage.status === 401) {
      onTokenSave('');
    }
    toast.error(updateCameraImage.data.message);
    return false;
  };

  return (
    <SettingsBlock
      title={i18n.t('camera.reset_background_image.title')}
      description={i18n.t('camera.reset_background_image.body')}
      buttonLabel={i18n.t('button.site_ok')}
      buttonLoading={loading}
      onClick={() => {
        onUpdateImageCamera();
      }}
    />
  );
}
