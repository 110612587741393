import i18n from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { ReactComponent as OfflineCamera } from '../../assets/icons/offline-camera.svg';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { isDefined } from '../../utils/typeUtils';
import { ImageLoader } from '../elements/ImageLoader';

interface ImageProps {
  id: number;
  cameraPerRow: number;
  className?: string;
}

export const DisplayImage = ({ id, cameraPerRow, className }: ImageProps) => {
  const { theme } = useThemeContext();
  const { fetchCameraImageUrl } = useCameraContext();
  const [cameraUrl, setCameraUrl] = useState<string | null>();
  const [isImageLoading, setIsImageLoading] = useState(false);

  const fetchImageUrl = useCallback(
    async (cameraId: number) => {
      try {
        setIsImageLoading(true);
        const imageUrl = await fetchCameraImageUrl(cameraId);
        setCameraUrl(imageUrl);
      } catch (error) {
        console.error('Error fetching new image URL:', error);
      } finally {
        setIsImageLoading(false);
      }
    },
    [fetchCameraImageUrl],
  );

  useEffect(() => {
    if (!isDefined(cameraUrl) && !isImageLoading) {
      fetchImageUrl(id);
    }
  }, [id, fetchImageUrl, isImageLoading, cameraUrl]);

  const convertHeight = 80 * cameraPerRow;
  const showLoading = isImageLoading || !isDefined(cameraUrl);
  return (
    <>
      {showLoading && <ImageLoader style={{ height: convertHeight }} />}
      {cameraUrl ? (
        <Image
          onError={() => {
            setCameraUrl(null);
            setIsImageLoading(false);
          }}
          height={convertHeight}
          width="100%"
          style={{ objectFit: 'cover' }}
          className={`${className} ${isImageLoading ? 'd-none' : ''} ph-no-capture`}
          src={cameraUrl}
        />
      ) : (
        <div
          className={`observation-image-container-offline  ${isImageLoading ? 'd-none' : ''} ${theme === 'light' ? 'dark' : 'light'}`}
          style={{ height: convertHeight }}
        >
          <OfflineCamera />
          <p>{i18n.t('camera.camera_not_found')}</p>
        </div>
      )}
    </>
  );
};
