/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ObservationModalBody } from './ObservationModalBody';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  GetObservationsNoOpQueryBuilder,
  GetObservationsQueryBuilder,
} from '../../hooks/graphql/observations';
import { spacing } from '../../scss/spacing';
import { ObservationProp } from '../../typescript/observation/observation';
import { BWButton } from '../elements/BWButton';
import { Loader } from '../elements/Loader';
import { SectionTitle } from '../elements/SectionTitle';

interface Props {
  buildGetObservationsQuery?: GetObservationsQueryBuilder;
  observation?: ObservationProp;
  showModal: boolean;
  paginationDefaults?: {
    pageNumber?: number;
    limit?: number;
  };
  isLoading?: boolean;
  onCloseClick: () => void;
}

export function ObservationModal({
  showModal,
  observation,
  isLoading,
  paginationDefaults,
  buildGetObservationsQuery = GetObservationsNoOpQueryBuilder,
  onCloseClick,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { featureFlags } = useAuthContext();

  // State
  const [showLabel, setShowLabel] = useState<boolean>(
    featureFlags.observations.show_labels_by_default || false,
  );

  if (!showModal || !observation) {
    return null;
  }

  const handleCloseModal = () => {
    onCloseClick();
  };

  const showAnnotation = featureFlags.observations.show_annotation;
  const hasAnnotation =
    (showAnnotation &&
      observation &&
      observation.id >= Number(showAnnotation)) ||
    false;

  return (
    <Modal
      centered
      contentClassName={theme}
      show={showModal}
      onHide={handleCloseModal}
      css={css`
        .modal-dialog {
          min-width: min-content;

          .modal-content {
            min-width: fit-content;
            min-height: fit-content !important;

            .modal-header {
              padding-top: ${spacing(4)};
            }

            .modal-body {
              padding-bottom: ${spacing(6)};
            }
          }
        }
      `}
    >
      <Modal.Header>
        <SectionTitle
          title={`${i18n.t('modal.observation.title')}`}
          removeMargins
        />

        <BWButton
          icon={CancelIcon}
          variant="transparent"
          className="border-0 ms-auto"
          type="button"
          onClick={handleCloseModal}
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        {isLoading && (
          <div
            className={`position-absolute bottom-50 ${theme}`}
            style={{
              left: '48%',
              zIndex: 1,
              padding: 10,
              borderRadius: '50%',
            }}
          >
            <Loader main />
          </div>
        )}
        <ObservationModalBody
          observation={observation}
          buildGetObservationsQuery={buildGetObservationsQuery}
          hasAnnotations={hasAnnotation}
          paginationDefaults={paginationDefaults}
          hideLabel={!showLabel}
          setHideLabel={(value) => setShowLabel(!value)}
          onCloseModal={handleCloseModal}
        />
      </Modal.Body>
    </Modal>
  );
}
