import posthog, { Properties } from 'posthog-js';

let isInitialized = false;

export const initPosthog = () => {
  const apiKey = process.env.REACT_APP_POSTHOG_API_KEY;

  if (apiKey && process.env.REACT_APP_POSTHOG_DISABLE !== 'true') {
    posthog.init(apiKey, {
      api_host: 'https://eu.posthog.com',
      opt_out_capturing_by_default: true,
      capture_pageview: false,
    });

    isInitialized = true;
  }
};

export const Posthog = {
  identify: (id?: string, properties?: Properties) => {
    if (!isInitialized) return;

    posthog.identify(id, properties);
  },
  capture: (eventName: string, properties?: Properties | null) => {
    if (!isInitialized) return;

    posthog.capture(eventName, properties);
  },
  reset: () => {
    if (!isInitialized) return;

    posthog.reset();
  },
  optInTracking: () => {
    if (!isInitialized) return;

    posthog.opt_in_capturing();
  },
  optOutTracking: () => {
    if (!isInitialized) return;

    posthog.opt_out_capturing();
  },
};
