import { ApolloProvider } from '@apollo/client';
import { Profiler } from '@sentry/react';
import React, { useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PostHogPageView } from './components/pageview';
import { AuthProvider } from './contextapi/AuthProvider';
import { usePersistentStorageContext } from './contextapi/PersistentStorageProvider';
import { ThemeProvider } from './contextapi/ThemeProvider';
import { TranslationProvider } from './contextapi/TranslationProvider';
import { createApolloClient } from './graphql/client';
import { Router } from './routes/Router';

export function App() {
  const { userToken, siteId } = usePersistentStorageContext();

  const client = useMemo(
    () => createApolloClient(userToken, siteId),
    [userToken, siteId],
  );

  return (
    <CookiesProvider>
      <ThemeProvider>
        <Profiler>
          <ToastContainer
            autoClose={5000}
            hideProgressBar
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
          />
          <BrowserRouter>
            <PostHogPageView />
            <ApolloProvider client={client}>
              <AuthProvider>
                <TranslationProvider>
                  <Router />
                </TranslationProvider>
              </AuthProvider>
            </ApolloProvider>
          </BrowserRouter>
        </Profiler>
      </ThemeProvider>
    </CookiesProvider>
  );
}
