import 'moment/locale/es';
import 'moment/locale/en-gb';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { merge } from 'ts-deepmerge';
import { usePersistentStorageContext } from './PersistentStorageProvider';
import { fileStatus } from '../apis/api-csv';
import { userLogin } from '../apis/api-request';
import { UserToken } from '../apis/utils';
import { useGetUserQuery } from '../hooks/graphql/user';
import { ClientUser } from '../typescript/observation/assignee';
import {
  DEFAULT_FEATURE_FLAGS,
  excludeDemoAccounts,
  FeatureFlags,
  GqlCurrentUser,
  isBuddywiseCustomer,
} from '../typescript/user/user';
import { Posthog } from '../utils/Posthog';
import { isBoolean, isNull, isNumber, isString } from '../utils/typeUtils';

type AuthContextType = {
  user: GqlCurrentUser | undefined;
  isUserLoading: boolean;
  clientUsersList: ClientUser[];
  userToken: UserToken;
  route: {
    to: string;
    from: string;
  };
  featureFlags: FeatureFlags;
  isCSVStatus: string | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  saveUser: (updateUser: GqlCurrentUser | undefined) => void;
  refetchUser: ReturnType<typeof useGetUserQuery>['refetch'];
  setIsCSVStatus: (value: React.SetStateAction<string | null>) => void;
  onTokenSave: (token: string | null) => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams();
  const { userToken, siteId, persistUserToken, persistSiteId } =
    usePersistentStorageContext();
  const siteIdSearchParam = searchParams.get('siteId')
    ? Number(searchParams.get('siteId'))
    : undefined;
  const [user, setUser] = useState<GqlCurrentUser | undefined>();
  const [isCSVStatus, setIsCSVStatus] = useState<string | null>(null);
  const { featureFlagOverrides } = usePersistentStorageContext();

  const {
    loading: isUserLoading,
    error: userError,
    data: userData,
    refetch: refetchUser,
  } = useGetUserQuery({
    includeUsers: true,
  });

  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    setRoute((prev) => ({
      to: `${location.pathname}${location.search}`,
      from: prev.to,
    }));
  }, [location]);

  const onTokenSave = useCallback(
    (token: string | null) => {
      if (isString(token) && token.length > 0) {
        persistUserToken(token);
      } else {
        persistSiteId(null);
        persistUserToken(null);
      }
    },
    [persistSiteId, persistUserToken],
  );

  useEffect(() => {
    if (
      userError?.message === 'Authentication hook unauthorized this request'
    ) {
      onTokenSave('');
      refetchUser();
    }
  }, [userError, refetchUser, onTokenSave]);

  useEffect(() => {
    const currentUser = userData?.user.at(0);
    if (currentUser) {
      Posthog.identify(currentUser.trackingId, {
        $userId: currentUser.trackingId,
        $customerId: currentUser.customer.trackingId,
        $isAdmin: currentUser.isAdmin,
        $title: currentUser.title,
        $phoneNoVerified: currentUser.phoneNoVerified,
        $excludeFromTracking: currentUser.exclude_from_tracking || false,
      });

      setUser(currentUser);
    }
  }, [userData]);

  const clientUsersList = useMemo(() => {
    if (!user?.customer.users) {
      return [];
    }

    const { customer } = user;
    const { users } = user.customer;

    return isBuddywiseCustomer(customer)
      ? users
      : users.filter(excludeDemoAccounts);
  }, [user]);

  useEffect(() => {
    const currentUser = userData?.user.at(0);
    if (currentUser) {
      const siteIds = currentUser.customer.sites.map((site) => site.id);
      if (isNumber(siteIdSearchParam) && siteIds.includes(siteIdSearchParam)) {
        persistSiteId(siteIdSearchParam);
      } else if (isNull(siteId)) {
        persistSiteId(Math.min(...siteIds));
      }
    }
  }, [userData, siteIdSearchParam, siteId, persistSiteId]);

  useEffect(() => {
    const fetchData = async () => {
      const makeAPICall = async () => {
        try {
          const response = await fileStatus(userToken);
          const { files } = response.data;
          if (response.status === 401) {
            onTokenSave(null);
            setIsCSVStatus(null);
          } else if (
            Object.keys(files).length === 0 &&
            files.constructor === Object
          ) {
            setIsCSVStatus(null);
          } else if (files.CSV_EXPORT === 'pending') {
            setIsCSVStatus(files.CSV_EXPORT);
            setTimeout(makeAPICall, 2000);
          } else if (files.CSV_EXPORT && files.CSV_EXPORT === 'done') {
            setIsCSVStatus(files.CSV_EXPORT);
          } else {
            setTimeout(makeAPICall, 2000);
          }
        } catch (err) {
          setIsCSVStatus(null);
        }
      };

      makeAPICall();
    };

    if (user) {
      fetchData();
    }
  }, [isCSVStatus, user, userToken, onTokenSave]);

  const featureFlags = useMemo(
    () => merge(DEFAULT_FEATURE_FLAGS, user?.customer.feature_flags || {}),
    [user],
  );

  if (featureFlagOverrides) {
    if (isBoolean(featureFlagOverrides.dashoard_v2)) {
      featureFlags.dashboard.show_page = featureFlagOverrides.dashoard_v2;
    }
    if (isBoolean(featureFlagOverrides.camera_details_page)) {
      featureFlags.cameras.show_details_page =
        featureFlagOverrides.camera_details_page;
    }
    if (isBoolean(featureFlagOverrides.observation_quick_view_v2)) {
      featureFlags.observations.show_extended_quick_view =
        featureFlagOverrides.observation_quick_view_v2;
    }
  }

  const context = useMemo(
    () => ({
      user,
      isUserLoading,
      userToken,
      route,
      featureFlags,
      isCSVStatus,
      clientUsersList,
      setIsCSVStatus,
      login: async (email: string, password: string) => {
        const loginUser = await userLogin({ email, password });
        if (loginUser.status === 200) {
          onTokenSave(loginUser.data.token);
          toast.success(loginUser.data.message);
          refetchUser();
          return true;
        }
        return false;
      },
      logout: async () => {
        Posthog.reset();
        setUser(undefined);
        onTokenSave(null);
        window.location.reload();
      },
      saveUser: (updateUser: GqlCurrentUser | undefined) => {
        setUser(updateUser);
      },
      refetchUser,
      onTokenSave,
    }),
    [
      onTokenSave,
      refetchUser,
      route,
      user,
      userToken,
      clientUsersList,
      isUserLoading,
      isCSVStatus,
      featureFlags,
    ],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
