import React, { Navigate, Route, Routes } from 'react-router-dom';
import { useAuthContext } from '../contextapi/AuthProvider';
import { ProtectedLayout } from '../layouts/ProtectedLayout';
import { AlertDetailsPage } from '../pages/AlertDetailsPage';
import { AlertsPage } from '../pages/AlertsPage';
import { CameraAnalyticsPage } from '../pages/CameraAnalyticsPage';
import { CameraDetailsPage } from '../pages/CameraDetailsPage';
import { CamerasPage } from '../pages/CamerasPage';
import { CreateAlertRulePage } from '../pages/CreateAlertRule';
import { DashboardPage } from '../pages/DashboardPage';
import { GroupDetailPage } from '../pages/GroupDetailPage';
import { GroupObservationsPage } from '../pages/GroupObservationsPage';
import { ObservationDetailsPage } from '../pages/ObservationDetailsPage';
import { ObservationListPage } from '../pages/ObservationListPage';
import { ProfilePage } from '../pages/ProfilePage';
import { SettingsPage } from '../pages/SettingsPage';
import { UpdateAlertRulePage } from '../pages/UpdateAlertRulePage';
import { withPageContext } from '../utils/trackingContext';

export function ProtectedRoutes() {
  const { featureFlags } = useAuthContext();

  const showDashboard = featureFlags.dashboard.show_page;
  return (
    <Routes>
      <Route element={<ProtectedLayout />}>
        <Route
          path="/dashboard"
          element={
            showDashboard ? (
              withPageContext(<DashboardPage />, 'dashboard_page')
            ) : (
              <GroupObservationsPage />
            )
          }
        />

        <Route
          path="/observations"
          element={withPageContext(
            <ObservationListPage />,
            'observations_page',
          )}
        />
        <Route
          path="/group-observations/:groupId"
          element={withPageContext(
            <GroupDetailPage />,
            'scenario_observations_page',
          )}
        />

        <Route
          path="/observation/:slug"
          element={withPageContext(
            <ObservationDetailsPage />,
            'observation_details_page',
          )}
        />
        <Route
          path="/cameras"
          element={withPageContext(<CamerasPage />, 'cameras_page')}
        />
        <Route
          path="/camera/:cameraId"
          element={withPageContext(
            <CameraDetailsPage />,
            'camera_details_page',
          )}
        />
        <Route
          path="/camera-analytics/:slug"
          element={<CameraAnalyticsPage />}
        />

        <Route
          path="/alerts"
          element={withPageContext(<AlertsPage />, 'alerts_page')}
        />
        <Route path="/alerts/create" element={<CreateAlertRulePage />} />
        <Route path="/alerts/edit/:slug" element={<UpdateAlertRulePage />} />
        <Route path="/alerts/:slug" element={<AlertDetailsPage />} />

        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/profile/:slug" element={<ProfilePage />} />

        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
}
