/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { ObservationDetail } from './ObservationDetail';
import { ReactComponent as AssigneeIcon } from '../../../assets/icons/modal/observation/assignee.svg';
import { ReactComponent as CameraIcon } from '../../../assets/icons/modal/observation/camera.svg';
import { ReactComponent as DateIcon } from '../../../assets/icons/modal/observation/date.svg';
import { ReactComponent as ScenarioIcon } from '../../../assets/icons/modal/observation/scenario.svg';
import { ReactComponent as StatusIcon } from '../../../assets/icons/modal/observation/status.svg';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { getScenarioTitle } from '../../../lib/features/scenario';
import { spacing } from '../../../scss/spacing';
import {
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  ObservationProp,
  UNKNOWN_PRIORITY,
} from '../../../typescript/observation/observation';
import { PriorityIcon } from '../../observation/PriorityIcon';

type Props = {
  observation: ObservationProp;
};

export function ObservationDetailsRow({ observation }: Props) {
  const { theme } = useThemeContext();
  const { getScenarioByName } = useScenarioContext();

  const scenario = getScenarioByName(observation.customer_scenario_label.name);
  const scenarioTitle =
    (scenario && getScenarioTitle(scenario)) || i18n.t('scenario.unknown');
  const priority =
    OBSERVATION_PRIORITIES.find(
      (priority) => priority.value === observation.priority,
    ) || UNKNOWN_PRIORITY;
  const statusTitle =
    OBSERVATION_STATUSES.find((status) => status.value === observation.status)
      ?.title || 'status.unknown';

  return (
    <div
      style={{
        borderTop: '1px solid',
        display: 'flex',
        alignItems: 'center',
        gap: spacing(3),
        padding: `${spacing(2)}  ${spacing(3)}`,
        minHeight: '60px',
      }}
      className={`border-${theme} text-nowrap`}
      css={css`
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 768px) {
          justify-content: flex-start;
        }
      `}
    >
      <ObservationDetail
        title={moment
          .unix(observation.system_timestamp)
          .format('DD MMM, HH:mm:ss')}
        icon={<DateIcon />}
      />
      <ObservationDetail title={scenarioTitle} icon={<ScenarioIcon />} />
      <ObservationDetail
        title={observation.camera?.name || i18n.t('td.camera_no_longer_exists')}
        icon={<CameraIcon />}
      />
      <ObservationDetail
        title={observation.responder || i18n.t('td.not_assigned')}
        icon={<AssigneeIcon />}
      />
      <ObservationDetail title={i18n.t(statusTitle)} icon={<StatusIcon />} />
      <ObservationDetail
        title={i18n.t(priority.title)}
        icon={<PriorityIcon priority={priority.value} />}
      />
    </div>
  );
}
