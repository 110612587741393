import i18n from 'i18next';
import React, { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userResetPassword } from '../apis/api-request';
import { BWButton } from '../components/elements/BWButton';
import { InputField } from '../components/elements/InputField';
import { LoginContainer } from '../components/login/LoginContainer';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTrackEvent } from '../hooks/tracking';

export const ForgotPasswordPage = () => {
  const { theme } = useThemeContext();
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const trackEvent = useTrackEvent();

  const handlePasswordReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const resetPassword = await userResetPassword({ email });
    setLoading(false);
    trackEvent('form_submit', {
      form_name: 'reset_password',
      success: resetPassword.status === 200,
    });
    if (resetPassword.status === 200) {
      toast.success(resetPassword.data.message);
      return true;
    }
    toast.error(resetPassword.data.message);
    return false;
  };

  return (
    <LoginContainer>
      <h5 className="mb-12">{i18n.t('reset_password.title')}</h5>
      <p className="mb-24">{i18n.t('reset_password.body')}</p>

      <Form onSubmit={handlePasswordReset}>
        <InputField
          className="mb-12"
          controlId="formEmail"
          type="email"
          required
          title={`${i18n.t('input.email.title')}`}
          placeholder={`${i18n.t('input.email.placeholder')}`}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="mb-12">
          <Link
            to="/login"
            className={`${theme === 'dark' ? 'white-color' : 'dark-color'}`}
            style={{ textDecoration: 'none' }}
          >
            <BWButton
              title={i18n.t('button.back_to_signin')}
              variant="transparent"
              style={{ padding: 0 }}
              trackingContext={{
                action: 'open_login_page',
              }}
            />
          </Link>
        </div>

        <BWButton
          title={i18n.t('button.send_reset_link')}
          className="button-with-loader w-100"
          variant="primary"
          type="submit"
          disabled={loading}
          loading={loading}
          doNotTrack
        />
      </Form>
    </LoginContainer>
  );
};
