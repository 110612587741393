import i18n from 'i18next';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { generateTimeRangeUrlParam } from '../../lib/helpers/timeRange';
import {
  DaysListProps,
  TIME_RANGES,
  timeRangeOptions,
} from '../../typescript/grouping/grouping-observation';
import { CustomDropdown, DropdownProps } from '../elements/CustomDropdown';

type Props = {
  className?: string;
  onChange?: (timeRange: DaysListProps) => void;
};

export function TimeRangeDropdown({ className = '', onChange }: Props) {
  const [_, setSearchParams] = useSearchParams();

  const { timeRange } = useTimeRangeContext();

  const updateTimeRangeQuery = useCallback(
    (timeRange: DaysListProps) => {
      setSearchParams((searchParams) => {
        searchParams.set('query', generateTimeRangeUrlParam(timeRange, true));
        return searchParams;
      });
    },
    [setSearchParams],
  );

  const { text, value: rangeFrom, end: rangeTo } = timeRange;
  const timeRangesData = TIME_RANGES.map((item: DaysListProps) => ({
    ...item,
    title: `${i18n.t(item.title)}`,
    value: text === item.text && rangeFrom ? rangeFrom : item.value,
    end: text === item.text && rangeTo ? rangeTo : item.end,
  }));

  return (
    <CustomDropdown
      className={className}
      title={`${i18n.t(timeRange.title)}`}
      dropdownData={timeRangesData}
      icon={CalendarIcon}
      onClick={(selectedItem: DropdownProps) => {
        const selected = { ...selectedItem } as DaysListProps; // TODO: Fix this type casting

        if (selected.isDatePicker) {
          const dateArray = String(selected.value)
            .split(',')
            .map((item) => Number(item));
          selected.value = Number(dateArray[0]);
          selected.end = Number(dateArray[1]);
        }

        updateTimeRangeQuery(selected);
        if (onChange) {
          onChange(selected);
        }
      }}
      trackingContext={{
        action: 'change_time_range',
        dropdown_value: timeRangeOptions[timeRange.text],
      }}
    />
  );
}
