/* eslint-disable react/no-unused-prop-types */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

export type TrackingContext = {
  page?: string;
  element?: string;
};

const TrackingReactContext = createContext<TrackingContext>({});

export const useTrackingContext = () => useContext(TrackingReactContext);

type Props = PropsWithChildren<TrackingContext>;

export const TrackingProvider = ({ page, element, children }: Props) => {
  const context = useMemo(
    () =>
      ({
        page,
        element,
      }) satisfies TrackingContext,
    [page, element],
  );

  return (
    <TrackingReactContext.Provider value={context}>
      {children}
    </TrackingReactContext.Provider>
  );
};
