import React from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent.d';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useTrackEvent } from '../../../hooks/tracking';
import {
  Scenario,
  ScenarioApiName,
  ScenarioName,
} from '../../../lib/features/scenario';
import { spacing } from '../../../scss/spacing';
import { ScenarioLabel } from '../../elements/ScenarioLabel';

type Props = {
  payload?: Payload[];
  onScenarioClick: (scenario: Scenario) => void;
  hiddenScenarios: Set<ScenarioName>;
  scenarioColors?: Partial<Record<ScenarioApiName, string>>;
};

export function ScenariosLegend({
  payload = [],
  onScenarioClick,
  hiddenScenarios,
  scenarioColors,
}: Props) {
  const { getScenariosByName } = useScenarioContext();
  const trackEvent = useTrackEvent();

  const scenarioNames = payload.map((item) => item.dataKey) as ScenarioName[];

  const scenarios = getScenariosByName(scenarioNames);

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginTop: spacing(2),
        gap: spacing(2),
      }}
    >
      {scenarios.map((scenario) => (
        <ScenarioLabel
          key={scenario.name}
          scenario={scenario}
          scenarioColors={scenarioColors}
          onClick={() => {
            onScenarioClick(scenario);
            trackEvent('bar_chart_legend_click', {
              legend_name: scenario.name,
            });
          }}
          isHighlighted={!hiddenScenarios.has(scenario.name)}
        />
      ))}
    </div>
  );
}
