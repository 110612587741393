import i18n from 'i18next';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ObservationCharts } from '../components/charts-container/ObservationCharts';
import { SiteOverview } from '../components/dashboard/SiteOverview';
import { SectionTitle } from '../components/elements/SectionTitle';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useAuthContext } from '../contextapi/AuthProvider';
import { assertUnreachable } from '../utils/typeUtils';

export const DashboardPage = () => {
  const { user } = useAuthContext();

  const greetings = useMemo(() => {
    const currentHour = Number(moment().format('HH'));

    switch (true) {
      case currentHour >= 6 && currentHour < 12:
        return i18n.t('text.good_morning');
      case currentHour >= 12 && currentHour < 15:
        return i18n.t('text.good_afternoon');
      case currentHour >= 15 && currentHour < 20:
        return i18n.t('text.good_evening');
      case currentHour >= 20 || currentHour < 6:
        return i18n.t('text.good_night');
      default:
        return assertUnreachable();
    }
  }, []);

  const nameOrEmail = user?.username ? user?.username : user?.email;

  return (
    <>
      <div className="d-flex mt-24">
        <div className="mb-32 flex-1 d-flex align-items-center">
          <h5 className="m-0 ph-no-capture">
            {`${greetings}${nameOrEmail && ', '}${nameOrEmail} 👋`}
          </h5>
        </div>
      </div>
      <SiteOverview />
      <Row className="group-observations mt-32">
        <Col
          md={12}
          className="mb-20 d-flex align-items-center justify-content-between"
        >
          <SectionTitle
            title={i18n.t('dashboard.analytics_overview')}
            removeMargins
          />
          <TimeRangeDropdown />
        </Col>
      </Row>
      <ObservationCharts />
    </>
  );
};
