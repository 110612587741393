import React from 'react';
import { Image } from 'react-bootstrap';

interface Props {
  className?: string;
  imageUrl: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
}

export function ImageViewer({
  className,
  imageUrl,
  width = '100%',
  height,
  onClick,
}: Props) {
  return (
    <div
      className={`canvas-container ${className}`}
      aria-hidden="true"
      onClick={onClick}
    >
      <Image width={width} height={height} src={imageUrl} id="image" />
    </div>
  );
}
