import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Posthog } from '../utils/Posthog';

export function PostHogPageView() {
  const location = useLocation();

  // Track pageviews
  useEffect(() => {
    Posthog.capture('$pageview', {
      $current_url: window.location.href,
    });
  }, [location]);

  return null;
}
