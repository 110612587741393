/* eslint-disable no-nested-ternary */
import i18n from 'i18next';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { generateTimeRangeUrlParam } from '../../lib/helpers/timeRange';
import {
  COLOR_STATUS_ERROR,
  COLOR_STATUS_NEUTRAL,
  COLOR_STATUS_SUCCESS,
} from '../../scss/colors';
import {
  DaysListProps,
  GroupingItemProp,
} from '../../typescript/grouping/grouping-observation';
import { ScenarioLabel } from '../elements/ScenarioLabel';
import { TrendIcon, TrendIconColor } from '../icons/TrendIcon';

interface Props {
  observation: GroupingItemProp;
  selectedDay: DaysListProps;
}

export function ObservationRow({ observation, selectedDay }: Props) {
  const navigate = useNavigate();
  const { getScenariosByApiName } = useScenarioContext();

  const scenarios = getScenariosByApiName(observation.scenario_names);

  const isDownwardTrend = observation.trend <= 0;
  const colorForNull = COLOR_STATUS_NEUTRAL;
  const colorForSuccess = COLOR_STATUS_SUCCESS;
  const colorForError = COLOR_STATUS_ERROR;
  const trendColor = !observation.trend
    ? colorForNull
    : isDownwardTrend
      ? colorForSuccess
      : colorForError;

  const trendIconColor = !observation.trend
    ? TrendIconColor.PREFERRED
    : isDownwardTrend
      ? TrendIconColor.PREFERRED
      : TrendIconColor.UNDESIRED;

  return (
    <tr
      className="add-cursor"
      onClick={() =>
        navigate(
          `/group-observations/${observation.id}?${generateTimeRangeUrlParam(selectedDay)}`,
        )
      }
    >
      <td>
        {scenarios.length === 0
          ? i18n.t('td.scenario_not_found')
          : scenarios.map((scenario) => (
              <ScenarioLabel key={scenario.name} scenario={scenario} />
            ))}
      </td>
      <td>
        {observation.camera_names.slice(0, 4).map((item) => (
          <span key={item} className="camera-name rounded-pill ph-no-capture">
            {item}
          </span>
        ))}

        {observation.camera_names.length > 4 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <span className="ph-no-capture">
                  {observation.camera_names
                    .slice(4, observation.camera_names.length)
                    .toString()
                    .replaceAll(',', ', ')}
                </span>
              </Tooltip>
            }
          >
            <span className="show-more-cameras ph-no-capture">
              {
                observation.camera_names.slice(
                  4,
                  observation.camera_names.length,
                ).length
              }{' '}
              {i18n.t('list.others')}
            </span>
          </OverlayTrigger>
        )}
      </td>
      <td>
        {!observation.trend ? (
          'N/A'
        ) : (
          <>
            <TrendIcon
              direction={isDownwardTrend ? 'down' : 'up'}
              strokeColor={trendIconColor}
            />
            <span
              className="ms-1 ph-no-capture"
              style={{ color: trendColor, fontWeight: 'bold' }}
            >
              {`${String(Math.trunc(observation.trend)).replace('-', '')}%`}
            </span>
          </>
        )}
      </td>
      <td>
        <span className="ph-no-capture">{observation.count}</span>
      </td>
    </tr>
  );
}
