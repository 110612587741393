/* eslint-disable import/no-extraneous-dependencies */
import { saveAs } from 'file-saver';
import i18n from 'i18next';
import React, { useState } from 'react';
import { BWButton } from './BWButton';
import { BUDDYWISE_API_URL } from '../../apis/api-request';
import { globalSiteId } from '../../apis/tokens';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';

type Props = {
  imageUrl?: string;
  onClick?: () => void;
};

export function ImageDownloader({ imageUrl, onClick }: Props) {
  const { userToken } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload =
    onClick ||
    (imageUrl
      ? () => {
          setLoading(true);
          setLoading(true);
          fetch(
            `${BUDDYWISE_API_URL}/v2/images/download-image/?url=${btoa(imageUrl)}`,
            {
              method: 'get',
              headers: {
                Authorization: `Bearer ${userToken}`,
                ...(globalSiteId && { 'X-Site-Id': `${globalSiteId}` }),
              },
            },
          )
            .then((response) => response.blob())
            .then((blob) => {
              saveAs(blob, 'image.jpg');
            })
            .catch((error) => {
              console.error('Error downloading image:', error);
            })
            .finally(() => setLoading(false));
        }
      : undefined);

  return (
    <BWButton
      disabled={loading}
      loading={loading}
      title={`${i18n.t('button.download')}`}
      icon={DownloadIcon}
      iconProps={{ stroke: '#1F2321' }}
      onClick={handleDownload}
      className="button-with-loader"
      variant="outline"
    />
  );
}
