import i18n from 'i18next';
import React, { PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';
import { CustomPlaceholder } from './CustomPlaceholder';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  EventPropertiesWithContext,
  useTrackEvent,
} from '../../hooks/tracking';
import { spacing } from '../../scss/spacing';
import { SVGComponent } from '../../typescript/utils';
import { Nullable } from '../../utils/typeUtils';

type Props = PropsWithChildren<{
  title?: Nullable<string>;
  badge?: Nullable<JSX.Element>;
  footer?: Nullable<string>;
  icon?: SVGComponent;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  trackingContext?: EventPropertiesWithContext['button_click'];
}>;

export const DetailCard = ({
  children,
  icon: Icon,
  title,
  badge,
  footer,
  isLoading = false,
  onClick,
  trackingContext,
}: Props) => {
  const { theme } = useThemeContext();
  const trackEvent = useTrackEvent();

  return (
    <Card
      className={`card-${theme} border border-${theme} border-radius placeholder-glow`}
      style={{ minHeight: '191px' }}
    >
      <Card.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Card.Text
          className="mb-12"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {Icon && <Icon className="me-2 icon" />}
          {title}
        </Card.Text>

        <div
          style={{
            flex: '0.7',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: `${spacing(3)}`,
          }}
        >
          <h4 className="ph-no-capture weight-400 d-flex flex-column justify-content-center align-items-center m-0 w-100">
            {isLoading ? (
              <CustomPlaceholder style={{ width: '100%' }} />
            ) : (
              children
            )}
          </h4>
          {isLoading ? (
            <CustomPlaceholder
              style={{
                width: '66%',
                height: 24,
              }}
            />
          ) : (
            badge
          )}
        </div>
      </Card.Body>
      <Card.Footer
        style={{
          backgroundColor: 'transparent',
          color: '#6b7272',
          maxHeight: '35px',
        }}
      >
        <p
          className="m-0"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: footer ? 'space-between' : 'center',
          }}
        >
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {footer}
          </span>
          <span
            className="ps-2 weight-600 add-cursor"
            aria-hidden
            onClick={(event) => {
              trackEvent('button_click', {
                ...trackingContext,
              });
              if (onClick) {
                onClick(event);
              }
            }}
          >
            {i18n.t('button.view')}
          </span>
        </p>
      </Card.Footer>
    </Card>
  );
};
