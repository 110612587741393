import { useCallback, useState } from 'react';
import {
  defaultModalOptions,
  ModalOptions,
} from '../typescript/components/modal';

export type ModalOpenOptions = Omit<ModalOptions, 'show' | 'type'> & {
  type?: ModalOptions['type'];
};

export function useConfirmationModal() {
  const [confirmationModalOptions, setModalOptions] =
    useState<ModalOptions>(defaultModalOptions);

  const openConfirmationModal = useCallback(
    (options: ModalOpenOptions) => {
      setModalOptions({ type: 'default', ...options, show: true });
    },
    [setModalOptions],
  );

  const closeConfirmationModal = useCallback(() => {
    setModalOptions(defaultModalOptions);
  }, [setModalOptions]);

  return {
    confirmationModal: confirmationModalOptions,
    openConfirmationModal,
    closeConfirmationModal,
  };
}
