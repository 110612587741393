import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  EventPropertiesWithContext,
  useTrackEvent,
} from '../../hooks/tracking';
import { SVGComponent } from '../../typescript/utils';

type Props = {
  href: string;
  icon: SVGComponent;
  label: string;
  trackingContext?: EventPropertiesWithContext['button_click'];
};

export function NavigationItem({
  href,
  icon: IconComponent,
  label,
  trackingContext,
}: Props) {
  const trackEvent = useTrackEvent();

  return (
    <NavLink
      className="nav-link"
      to={href}
      onClick={() => {
        trackEvent('button_click', { ...trackingContext });
      }}
    >
      <IconComponent className="icon" />
      <h6 className="d-lg-none ms-4">{label}</h6>
    </NavLink>
  );
}
