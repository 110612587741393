import { gql } from '@apollo/client';

export const GROUP_FRAGMENT = gql`
  fragment GroupFragment on ListObservationGroupsOutput {
    id
    name
    camera_names
    cameras {
      id
      count
      name
    }
    count
    previous_count
    scenario_names
    trend
    timeseries {
      count
      range
    }
    observations_by_time_of_day {
      evening
      midday
      morning
    }
  }
`;

export const GET_GROUPDATA = gql`
  ${GROUP_FRAGMENT}

  query GET_GROUPS(
    $from: Int!
    $until: Int!
    $customer_scenario_label_ids: [Int]
  ) {
    observation_group_statistics_list(
      from: $from
      until: $until
      customer_scenario_label_ids: $customer_scenario_label_ids
    ) {
      ...GroupFragment
    }

    observations_aggregate {
      aggregate {
        count
      }
    }
  }
`;
