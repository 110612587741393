/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import React, { useState } from 'react';
import { Dropdown, FormCheck } from 'react-bootstrap';
import { DateRangePicker } from 'rsuite';
import { buttonCss, BWButton } from './BWButton';
import { Loader } from './Loader';
import { ScenarioLabel } from './ScenarioLabel';
import dropdownIcon from '../../assets/icons/dropdown.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  EventPropertiesWithContext,
  useTrackEvent,
} from '../../hooks/tracking';
import { getScenarioTitle, Scenario } from '../../lib/features/scenario';
import {
  GRAY_MIDDLE,
  GRAY_SUBTLE,
  GRAY_WARM_DARKER_AA,
  SECONDARY_COLOR,
  SECONDARY_COLOR_COMPLIMENT,
} from '../../scss/colors';
import { fontSize, spacing } from '../../scss/spacing';
import { SVGComponent } from '../../typescript/utils';
import { isDefined, isNotNull } from '../../utils/typeUtils';

export type DropdownProps = {
  title: string | number;
  value: string | number | boolean;
  text?: string;
  end?: string | number | boolean;
  isDatePicker?: boolean;
  index?: number;
  isChecked?: boolean;
  scenario?: Scenario;
  isDisable?: boolean;
  subMenu?: Array<{
    title: string | number;
    value: string | number | boolean;
  }>;
};

type Props = {
  variant?: 'default' | 'checkbox' | 'scenario';
  className?: string;
  dropdownClassName?: string;
  menuClassName?: string;
  border?: 'rounded-0' | 'rounded' | 'rounded-pill';
  hideContextData?: boolean;
  title?: string | number | null;
  dropdownData: DropdownProps[];
  icon?: SVGComponent;
  rightIcon?: SVGComponent;
  loading?: boolean;
  disabled?: boolean;
  showCheckboxes?: boolean;
  showConfirmButton?: boolean;
  selectedAll?: boolean;
  selectAllTitle?: string;
  confirmTitle?: string;
  isThreeDot?: boolean;
  onSelectedAll?: (isAllCheck: boolean, fromChild: boolean) => void;
  onClick?: (value: DropdownProps) => void;
  onConfirm?: (value: DropdownProps[]) => void;
  trackingContext?: EventPropertiesWithContext['dropdown_select'];
  style?: React.CSSProperties;
  customCss?: ReturnType<typeof css>;
};

export function mapScenarioToDropdownProps(
  input: Scenario | [Scenario, boolean],
): DropdownProps {
  const [scenario, isChecked] = Array.isArray(input) ? input : [input, false];
  const title = getScenarioTitle(scenario) || '';
  return {
    title,
    value: scenario.id,
    text: undefined,
    end: undefined,
    isDatePicker: false,
    index: undefined,
    isChecked,
    scenario,
    isDisable: undefined,
    subMenu: undefined,
  };
}

export const dropdownCss = css`
  &.dropdown {
    .dropdown {
      &-menu {
        padding: ${spacing(1)} 0;
        box-shadow: 0px 5px 10px 0px #00000030;

        .rs-picker {
          width: 100%;
          padding: 0 ${spacing(1)};
          &-toggle {
            width: 100%;
            border: none;
            border-radius: 0 !important;
            box-shadow: none;
            justify-content: space-between;

            .rs-stack {
              justify-content: space-between;
              flex: 1;

              input {
                box-shadow: none;
                font-weight: 400 !important;
              }
            }

            &-value {
              font-weight: 400 !important;
            }
          }
        }
      }

      &-menu-dark {
        background-color: ${SECONDARY_COLOR};
        border-color: ${GRAY_WARM_DARKER_AA};
      }

      &-toggle {
        display: flex;
        align-items: center;

        &::after {
          border: none;
          margin-left: ${spacing(3)};
          content: url(${dropdownIcon});
        }
      }
      &-item {
        font-size: ${fontSize(2)};
        padding: 0 ${spacing(4)};
        height: 40px;
        display: flex;
        align-items: center;

        .form-check {
          margin: 0;
          cursor: pointer;

          &-label,
          &-input {
            cursor: pointer;
          }
        }

        &:hover,
        &:focus,
        &:active,
        &:first-of-type:active,
        &.show,
        &:disabled {
          text-decoration: none;
        }

        &:focus,
        &:active {
          color: var(--bs-dropdown-link-hover-color);
          background-color: ${GRAY_SUBTLE} !important;
        }
      }
    }
  }

  &.three-dot-dropdown {
    border: none;

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  &.dropend {
    .dropdown-toggle {
      &::after {
        border: none;
        margin-left: ${spacing(3)};
        content: url(${dropdownIcon});
      }
    }
  }
`;

const dateRangePickerCss = css`
  &.dropdown-cal {
    .rs-stack-item {
      svg {
        display: none;
      }
    }

    .rs-btn {
      background-color: transparent !important;
      border: none;
    }

    &:hover {
      background-color: var(--bs-dropdown-link-hover-bg);
    }
  }
  &.light-dropdown-cal {
    border-color: ${GRAY_MIDDLE};
    color: ${SECONDARY_COLOR};

    .rs-picker-toggle-placeholder {
      color: ${SECONDARY_COLOR};
    }
  }
  &.dark-dropdown-cal {
    .rs-picker-toggle-placeholder {
      color: white;
    }

    .rs-picker-toggle-value {
      color: white !important;
    }

    .rs-btn {
      background-color: transparent !important;
      border: none;
    }

    input {
      background-color: ${SECONDARY_COLOR_COMPLIMENT};
      color: white;
    }
  }
`;

const subMenuCss = css`
  width: 100%;
  .btn-outline-dark {
    background-color: transparent;
    border-radius: 0;

    &:hover {
      background-color: var(--bs-dropdown-link-hover-bg);
    }
  }

  .dropdown-toggle {
    border: none;
    width: 100%;
    justify-content: space-between;
    box-shadow: none;
    font-weight: 400;

    &::after {
      display: block !important;
      transform: rotate(270deg) !important;
    }
  }

  .dropdown-menu {
    // transform: translate3d(-180px, 0px, 0px) !important;
  }
`;

export function CustomDropdown({
  variant = 'default',
  className = '',
  dropdownClassName = '',
  menuClassName = '',
  border = 'rounded-pill',
  hideContextData = false,
  title = '',
  loading = false,
  disabled = false,
  dropdownData: dropdownItems,
  icon: Icon,
  rightIcon: RightIcon,
  showCheckboxes = false,
  showConfirmButton = false,
  selectedAll,
  selectAllTitle,
  confirmTitle,
  isThreeDot,
  onSelectedAll,
  onClick,
  onConfirm,
  trackingContext,
  style,
  customCss,
}: Props) {
  const { theme } = useThemeContext();
  const trackEvent = useTrackEvent();
  const [isOpen, setIsOpen] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  return (
    <Dropdown
      className={`${dropdownClassName} ${isThreeDot ? `three-dot-dropdown` : ''}`}
      css={[dropdownCss, customCss]}
      show={isOpen}
      onToggle={() => {
        if (!isCalenderOpen) {
          setIsOpen(!isOpen);
        }
      }}
      style={style}
    >
      <Dropdown.Toggle
        className={`${className} ${border} icon button-with-loader`}
        variant={`outline-${theme}`}
        style={isThreeDot ? { width: 40, height: 40, padding: 0 } : undefined}
        css={buttonCss}
        disabled={loading || disabled}
      >
        {Icon && <Icon className={isThreeDot ? '' : 'me-2'} />}
        {title}
        {RightIcon && <RightIcon className="ms-2" />}
        {loading && <Loader className="ms-12" />}
      </Dropdown.Toggle>

      <Dropdown.Menu
        variant={theme}
        className={`${showConfirmButton && 'pb-0'} ${menuClassName}`}
      >
        {variant === 'checkbox' ? (
          <>
            <div className="dropdown-item border-bottom">
              <FormCheck
                className={`check-box py-1 ${dropdownItems.every((item) => item.isChecked) || 'not-check-all'}`}
                checked={selectedAll}
                label={selectAllTitle}
                onChange={(e) => {
                  onSelectedAll?.(e.target.checked, false);
                }}
              />
            </div>
            {dropdownItems.map((item) => (
              <div
                className={`dropdown-item ${hideContextData ? 'ph-no-capture' : ''}`}
                key={item.title}
              >
                <FormCheck
                  className="check-box"
                  checked={item.isChecked}
                  label={item.title}
                  onChange={() => {
                    if (onClick && onSelectedAll) {
                      onClick(item);
                      onSelectedAll(true, true);
                    }
                  }}
                />
              </div>
            ))}
            {showConfirmButton && (
              <BWButton
                className="d-flex w-100 justify-content-center rounded-top-0"
                isSimpleRounded
                title={confirmTitle}
                variant="primary"
                disabled={
                  dropdownItems.filter((item) => item.isChecked).length === 0
                }
                onClick={() => onConfirm?.(dropdownItems)}
              />
            )}
          </>
        ) : variant === 'scenario' ? (
          <>
            {showCheckboxes && (
              <div className="dropdown-item border-bottom">
                <FormCheck
                  className={`check-box py-1 ${dropdownItems.every((item) => item.isChecked) || 'not-check-all'}`}
                  checked={selectedAll}
                  label={selectAllTitle}
                  onChange={(e) => {
                    if (onSelectedAll) {
                      onSelectedAll(e.target.checked, false);
                    }
                  }}
                />
              </div>
            )}
            {dropdownItems
              .map((item) =>
                !isDefined(item.scenario) ? null : showCheckboxes ? (
                  <div className="dropdown-item" key={item.scenario.name}>
                    <FormCheck
                      className="check-box"
                      checked={item.isChecked}
                      label={<ScenarioLabel scenario={item.scenario} />}
                      onClick={() => {
                        if (onClick && onSelectedAll) {
                          onClick(item);
                          onSelectedAll(true, true);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <Dropdown.Item
                    className="no-hover"
                    key={item.scenario.name}
                    onClick={() => onClick?.(item)}
                  >
                    <ScenarioLabel scenario={item.scenario} />
                  </Dropdown.Item>
                ),
              )
              .filter(isNotNull)}

            {showCheckboxes && showConfirmButton && (
              <BWButton
                className="d-flex w-100 justify-content-center rounded-top-0"
                isSimpleRounded
                title={confirmTitle}
                variant="primary"
                disabled={
                  dropdownItems.filter(({ isChecked }) => isChecked).length ===
                  0
                }
                onClick={() => onConfirm && onConfirm(dropdownItems)}
              />
            )}
          </>
        ) : (
          dropdownItems.map((item) => {
            if (item.isDatePicker) {
              return (
                <DateRangePicker
                  placement="auto"
                  key={item.title}
                  ranges={[]}
                  className={`${theme}-dropdown-cal dropdown-cal`}
                  css={dateRangePickerCss}
                  placeholder={item.title}
                  format="yyyy-MM-dd, HH:mm:ss"
                  appearance="default"
                  shouldDisableDate={(date: Date) => date > new Date()}
                  editable={false}
                  value={
                    item.text === 'custom' && item.value === 0
                      ? null
                      : [
                          new Date(
                            moment
                              .unix(Number(item.value))
                              .format('YYYY-MM-DD, HH:mm:ss'),
                          ),
                          new Date(
                            moment
                              .unix(Number(item.end))
                              .format('YYYY-MM-DD, HH:mm:ss'),
                          ),
                        ]
                  }
                  onOpen={() => setIsCalenderOpen(true)}
                  onClose={() => setIsCalenderOpen(false)}
                  onChange={(dates: [Date, Date] | null) => {
                    if (onClick) {
                      const convertedDates = dates
                        ? dates.map((date) => moment(date).unix())
                        : [];
                      const value = { ...item };
                      value.value = `${convertedDates}`;
                      onClick(value);
                    }
                  }}
                />
              );
            }

            if (item.subMenu) {
              return (
                <Dropdown
                  key={item.title}
                  className="child-dropdown-toggle"
                  drop="end"
                  css={subMenuCss}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    css={[
                      buttonCss,
                      css`
                        &.btn {
                          border: none;
                          color: ${theme === 'light'
                            ? SECONDARY_COLOR
                            : 'white'};
                          width: 100%;
                          &-outline-dark {
                            background: none;
                          }
                        }
                      `,
                    ]}
                    style={{
                      justifyContent: 'space-between',
                      fontWeight: 400,
                      boxShadow: 'none',
                    }}
                    disabled={loading || disabled}
                  >
                    {item.title}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    variant={theme}
                    style={{
                      maxHeight: '50vh',
                      overflowY: 'auto',
                    }}
                  >
                    {item.subMenu.map((childItem) => (
                      <Dropdown.Item
                        variant="outline-secondary"
                        className={`py-2 ${hideContextData ? 'ph-no-capture' : ''}`}
                        key={childItem.title}
                        onClick={() =>
                          onClick &&
                          onClick({
                            title: item.title,
                            value: item.value,
                            subMenu: [childItem],
                          })
                        }
                      >
                        {childItem.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              );
            }

            return (
              <Dropdown.Item
                disabled={item.isDisable}
                variant="outline-secondary"
                className={`py-2 ${hideContextData ? 'ph-no-capture' : ''}`}
                key={item.title}
                onClick={() => {
                  trackEvent('dropdown_select', {
                    action: trackingContext?.action || '',
                    dropdown_value: item.text || item.value,
                    ...trackingContext,
                  });
                  onClick?.(item);
                }}
              >
                {item.title}
              </Dropdown.Item>
            );
          })
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
