import { useCallback } from 'react';
import { useAuthContext } from '../contextapi/AuthProvider';
import {
  TrackingContext,
  useTrackingContext,
} from '../contextapi/TrackingProvider';
import { TimeRangeOption } from '../typescript/grouping/grouping-observation';
import { Posthog } from '../utils/Posthog';

type EventProperties = {
  button_click: {
    action?: string;
    button_text?: string;
  };
  switch_toggle: {
    title: string;
    value: boolean;
  };
  form_submit: { action?: string; form_name: string; success: boolean };

  tab_click: { tab_name: string };
  dropdown_select: {
    action: string;
    dropdown_value?: string | number | boolean;
  };
  image_click: { action?: string };

  language_change: { language: string };
  theme_change: { theme: string };

  modal_close: { modal: string };
  modal_confirm: { modal: string };

  checkbox_check: { action?: string };
  checkbox_uncheck: { action?: string };

  alert_create: {
    alertId: number;
    numCameras: number;
    scenario?: string;
    numObservations: number;
    timeframe: number;
    channels: Array<string | undefined>;
  };
  alert_update: {
    alertId: number;
    numCameras: number;
    scenario?: string;
    numObservations: number;
    timeframe: number;
    channels: Array<string | undefined>;
  };
  alert_delete: {
    alertId: number;
  };

  bar_chart_bar_click: {
    time_range: TimeRangeOption;
    bar_label: string;
  };
  bar_chart_legend_click: {
    // time_range: TimeRangeOption;
    legend_name: string;
  };
  donut_chart_legend_click: {
    time_range: TimeRangeOption;
    legend_name: string;
  };
  donut_chart_slice_click: {
    time_range: TimeRangeOption;
    slice_label: string;
  };

  camera_card_click: {
    action?: string;
  };
};
export type EventName = keyof EventProperties;

export type EventContext = {
  page: string;
  modal?: string;
};

export type EventPropertiesWithContext = {
  [K in EventName]: EventProperties[K] & {
    context?: TrackingContext;
  };
};

export const useTrackEvent = () => {
  const { user, featureFlags } = useAuthContext();
  const trackingContext = useTrackingContext();

  const customerTrackingId = user?.customer.trackingId || '';

  const trackEvent = useCallback(
    <T extends EventName>(
      eventName: T,
      properties: EventPropertiesWithContext[T] = {} as EventPropertiesWithContext[T],
    ) => {
      const pgProperties = {
        ...properties,
        customer_id: customerTrackingId,
        context_page: trackingContext.page,
        context_element: trackingContext.element,
        feature_flag_dashboard_v2: featureFlags.dashboard.show_page,
        feature_flag_camera_deatails_page:
          featureFlags.cameras.show_details_page,
        feature_flag_observation_quick_view:
          featureFlags.observations.show_extended_quick_view,
      };

      Posthog.capture(eventName, pgProperties);
      // const pgPropertiesWithoutUndefined = Object.entries(pgProperties).reduce(
      //   (acc, [key, value]) => {
      //     if (value !== undefined && key !== 'customer_id') {
      //       acc[key] = value;
      //     }
      //     return acc;
      //   },
      //   {} as Record<string, any>,
      // );
      // // eslint-disable-next-line no-console
      // console.log('PostHog:', eventName, pgPropertiesWithoutUndefined);
    },
    [trackingContext, customerTrackingId, featureFlags],
  );

  return trackEvent;
};
