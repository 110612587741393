import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { Col } from 'react-bootstrap';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import { CustomLegend } from './CustomLegend';
import { CustomTooltip } from './CustomTooltip';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { mapToScenarioName } from '../../lib/features/scenario';
import {
  CombinedDataItem,
  combineObservations,
  GroupingItemProp,
  updatedDate,
} from '../../typescript/grouping/grouping-observation';

interface Props {
  groupData: GroupingItemProp[];
}

export function ObservationTrends({ groupData }: Props) {
  const { customerScenarios } = useScenarioContext();
  const { timeRange } = useTimeRangeContext();

  const scenarioNames = groupData
    .flatMap((group) => group.scenario_names)
    .map(mapToScenarioName);

  const filteredScenario =
    scenarioNames &&
    customerScenarios.filter((element) => scenarioNames.includes(element.name));

  const addMissingDays: Array<GroupingItemProp> = groupData.map((group) => ({
    ...group,
    timeseries: updatedDate(
      group.timeseries.map((item) => ({
        count: item.count,
        date: moment(item.range).format('DD MMM'),
        range: item.range,
      })),
      timeRange.text,
      timeRange.value,
      timeRange.end,
      timeRange.isHourly,
    ),
  }));
  const formated: Array<CombinedDataItem> = combineObservations(
    addMissingDays,
    'DD MMM',
  );

  const graphTitle =
    timeRange.value === 1 || timeRange.value === 7
      ? i18n.t('group_observations.hourly_statistic')
      : i18n.t('group_observations.daily_statistic');
  return (
    <Col lg={12}>
      <h6 className="mb-20">{graphTitle}</h6>
      <ResponsiveContainer width="100%" height={200} className="ph-no-capture">
        <LineChart data={formated}>
          <CartesianGrid
            strokeOpacity={0.5}
            strokeDasharray="0"
            vertical={false}
          />

          <YAxis tickMargin={10} />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CustomTooltip />}
          />
          {filteredScenario.map((scenario) => (
            <Line
              key={scenario.name}
              type="monotone"
              dataKey={scenario.name}
              stroke={scenario.primaryColor}
              dot={false}
            />
          ))}
          <Legend content={<CustomLegend />} />
        </LineChart>
      </ResponsiveContainer>
    </Col>
  );
}
